import React, { useState } from 'react';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { useAuthenticate } from '../contexts/AuthenticateContext';
import { useCart } from '../contexts/CartProvider';
import { useTopUpCredits } from '../contexts/TopUpCreditsContext';

export default function Header() {
    const navigate = useNavigate();
    const { toggleTopUpCredits } = useTopUpCredits()

    // Use the useAuthenticate hook to access authentication context
    const { currentUser, toggleLoginForm, logout } = useAuthenticate();

    // State to track whether the mobile menu is open or closed
    const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

    // State to track the open/closed status of dropdown menus
    const [dropdownStates, setDropdownStates] = useState({
        settings: false,
        // Add more dropdowns here if needed
    });

    // Function to toggle the mobile menu
    const toggleMobileMenu = () => {
        setMobileMenuOpen(!isMobileMenuOpen);
    };

    // Function to toggle the state of a dropdown menu
    const toggleDropdown = (dropdown) => {
        setDropdownStates({
            ...dropdownStates,
            [dropdown]: !dropdownStates[dropdown],
        });
    };

    const handleLogOut = async () => {
        if (window.confirm('Do you want to log out?')) {
            toggleDropdown('settings');
            toggleMobileMenu();
            await logout();
        }
    }

    const { cart, toggleCartVisibility } = useCart();

    return (
        <header className="bg-white w-full container mx-auto lg:px-4 xl:px-0">
            <nav className="w-full flex items-center justify-between p-6 lg:px-0" aria-label="Global">
                <div className="flex lg:flex-1">
                    <Link to='/' className="-m-1.5 p-1.5 inline-flex items-center gap-2">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                            <path fillRule="evenodd" d="M20.599 1.5c-.376 0-.743.111-1.055.32l-5.08 3.385a18.747 18.747 0 0 0-3.471 2.987 10.04 10.04 0 0 1 4.815 4.815 18.748 18.748 0 0 0 2.987-3.472l3.386-5.079A1.902 1.902 0 0 0 20.599 1.5Zm-8.3 14.025a18.76 18.76 0 0 0 1.896-1.207 8.026 8.026 0 0 0-4.513-4.513A18.75 18.75 0 0 0 8.475 11.7l-.278.5a5.26 5.26 0 0 1 3.601 3.602l.502-.278ZM6.75 13.5A3.75 3.75 0 0 0 3 17.25a1.5 1.5 0 0 1-1.601 1.497.75.75 0 0 0-.7 1.123 5.25 5.25 0 0 0 9.8-2.62 3.75 3.75 0 0 0-3.75-3.75Z" clipRule="evenodd" />
                        </svg>
                        <span className='text-lime-400  text-3xl font-bold'>
                            <span className='text-black'>restyled</span>.ai
                        </span>
                    </Link>
                </div>
                {/* Mobile Menu Toggle Button */}
                <div className="flex lg:hidden gap-6 items-center">
                    {/* Cart Icon */}
                    <div className='cursor-pointer relative' onClick={toggleCartVisibility}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                            <path fillRule="evenodd" d="M7.5 6v.75H5.513c-.96 0-1.764.724-1.865 1.679l-1.263 12A1.875 1.875 0 0 0 4.25 22.5h15.5a1.875 1.875 0 0 0 1.865-2.071l-1.263-12a1.875 1.875 0 0 0-1.865-1.679H16.5V6a4.5 4.5 0 1 0-9 0ZM12 3a3 3 0 0 0-3 3v.75h6V6a3 3 0 0 0-3-3Zm-3 8.25a3 3 0 1 0 6 0v-.75a.75.75 0 0 1 1.5 0v.75a4.5 4.5 0 1 1-9 0v-.75a.75.75 0 0 1 1.5 0v.75Z" clipRule="evenodd" />
                        </svg>
                        <span className='absolute -top-3 -right-5 w-6 h-6 rounded-full bg-lime-400 text-black inline-flex items-center justify-center text-xs font-medium'>{cart?.length}</span>
                    </div>
                    <button type="button" onClick={toggleMobileMenu} className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700">
                        <span className="sr-only">Open main menu</span>
                        <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                        </svg>
                    </button>
                </div>
                {/* Desktop Menu */}
                <div className="hidden lg:flex lg:gap-x-12">
                    <NavLink to="/" className="text-sm font-semibold leading-6 cursor-pointer">
                        Home
                    </NavLink>
                    {/* <NavLink to="/about" className="text-sm font-semibold leading-6 text-gray-900 cursor-pointer">About</NavLink> */}
                    <NavLink to="/ai-image-restyle" className="text-sm font-semibold leading-6 cursor-pointer">
                        AI Image Restyle
                    </NavLink>
                    <NavLink to="/shop" className="text-sm font-semibold leading-6 cursor-pointer">
                        Shop
                    </NavLink>
                    <NavLink to="/track-order" className="text-sm font-semibold leading-6 cursor-pointer">
                        Track Order
                    </NavLink>
                </div>
                <div className="hidden lg:flex lg:flex-1 lg:justify-end gap-6 items-center">

                    {currentUser && currentUser?.creditsAvailable && (<div className='text-xs clear-start px-2 py-1 border rounded-md font-medium cursor-pointer' onClick={toggleTopUpCredits}>{currentUser?.creditsAvailable} Credits Left</div>)}

                    {/* Cart Icon */}
                    <div className='cursor-pointer relative' onClick={toggleCartVisibility}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                            <path fillRule="evenodd" d="M7.5 6v.75H5.513c-.96 0-1.764.724-1.865 1.679l-1.263 12A1.875 1.875 0 0 0 4.25 22.5h15.5a1.875 1.875 0 0 0 1.865-2.071l-1.263-12a1.875 1.875 0 0 0-1.865-1.679H16.5V6a4.5 4.5 0 1 0-9 0ZM12 3a3 3 0 0 0-3 3v.75h6V6a3 3 0 0 0-3-3Zm-3 8.25a3 3 0 1 0 6 0v-.75a.75.75 0 0 1 1.5 0v.75a4.5 4.5 0 1 1-9 0v-.75a.75.75 0 0 1 1.5 0v.75Z" clipRule="evenodd" />
                        </svg>
                        <span className='absolute -top-3 -right-5 w-6 h-6 rounded-full bg-lime-400 text-black inline-flex items-center justify-center text-xs font-medium'>{cart?.length}</span>
                    </div>

                    {/* Settings Dropdown */}
                    <div className="relative">
                        <button type="button" onClick={() => { currentUser ? toggleDropdown('settings') : toggleLoginForm() }} className="flex items-center gap-x-1 text-sm font-semibold leading-6 text-gray-900" aria-expanded={dropdownStates.settings ? 'true' : 'false'}>
                            {currentUser ?
                                <>
                                    {currentUser?.displayName}
                                    <svg className="h-5 w-5 flex-none text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                        <path fillRule="evenodd" d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z" clipRule="evenodd" />
                                    </svg>
                                </> :
                                <>
                                    Log in
                                    <span aria-hidden="true">&rarr;</span>
                                </>
                            }
                        </button>
                        {currentUser && dropdownStates.settings && (
                            <div className="absolute -right-8 top-full z-10 mt-3 w-screen max-w-md overflow-hidden rounded-3xl bg-white shadow-lg ring-1 ring-gray-900/5">
                                <div className="p-4">
                                    <div className="group relative flex items-center gap-x-6 rounded-lg p-4 text-sm leading-6 hover:bg-gray-50">
                                        <div className="flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                                            <svg className="h-6 w-6 text-gray-600 group-hover:text-indigo-600" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 6a7.5 7.5 0 107.5 7.5h-7.5V6z" />
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 10.5H21A7.5 7.5 0 0013.5 3v7.5z" />
                                            </svg>
                                        </div>
                                        <div className="flex-auto">
                                            <div className="inline-flex items-center gap-2 font-semibold text-gray-900 capitalize" onClick={() => { toggleDropdown('settings'); toggleTopUpCredits() }}>
                                                {currentUser?.subscriptionPlan} Plan
                                                <div className='text-xs clear-start px-2 py-1 rounded-md bg-lime-100 font-medium w-fit'>{currentUser?.creditsAvailable} Credits Left</div>
                                                <span className="absolute inset-0"></span>
                                            </div>
                                            <p className="mt-1 text-gray-600">Add additional credits at a rate of $0.001 per credit</p>
                                        </div>
                                    </div>
                                    <div className="group relative flex items-center gap-x-6 rounded-lg p-4 text-sm leading-6 hover:bg-gray-50">
                                        <div className="flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                                            <svg className="h-6 w-6 text-gray-600 group-hover:text-indigo-600" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 16.875h3.375m0 0h3.375m-3.375 0V13.5m0 3.375v3.375M6 10.5h2.25a2.25 2.25 0 002.25-2.25V6a2.25 2.25 0 00-2.25-2.25H6A2.25 2.25 0 003.75 6v2.25A2.25 2.25 0 006 10.5zm0 9.75h2.25A2.25 2.25 0 0010.5 18v-2.25a2.25 2.25 0 00-2.25-2.25H6a2.25 2.25 0 00-2.25 2.25V18A2.25 2.25 0 006 20.25zm9.75-9.75H18a2.25 2.25 0 002.25-2.25V6A2.25 2.25 0 0018 3.75h-2.25A2.25 2.25 0 0013.5 6v2.25a2.25 2.25 0 002.25 2.25z" />
                                            </svg>
                                        </div>
                                        <div className="flex-auto">
                                            <div className="block font-semibold text-gray-900" onClick={() => { toggleDropdown('settings'); navigate('/user-generations') }}>
                                                Generation History
                                                <span className="absolute inset-0"></span>
                                            </div>
                                            <p className="mt-1 text-gray-600">View all your AI-generated content so far.</p>
                                        </div>
                                    </div>
                                    {currentUser.userRole === 'admin' && (
                                        <div className="group relative flex items-center gap-x-6 rounded-lg p-4 text-sm leading-6 hover:bg-gray-50">
                                            <div className="flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                                                <svg className="h-6 w-6 text-gray-600 group-hover:text-indigo-600" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M15.042 21.672L13.684 16.6m0 0l-2.51 2.225.569-9.47 5.227 7.917-3.286-.672zM12 2.25V4.5m5.834.166l-1.591 1.591M20.25 10.5H18M7.757 14.743l-1.59 1.59M6 10.5H3.75m4.007-4.243l-1.59-1.59" />
                                                </svg>
                                            </div>
                                            <div className="flex-auto">
                                                <div className="block font-semibold text-gray-900" onClick={() => { toggleDropdown('settings'); navigate('/dashboard') }}>
                                                    Admin Panel
                                                    <span className="absolute inset-0"></span>
                                                </div>
                                                <p className="mt-1 text-gray-600">Access the admin panel for advanced management.</p>
                                            </div>
                                        </div>
                                    )}
                                    {/* <div className="group relative flex items-center gap-x-6 rounded-lg p-4 text-sm leading-6 hover:bg-gray-50">
                                        <div className="flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                                            <svg className="h-6 w-6 text-gray-600 group-hover:text-indigo-600" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M7.864 4.243A7.5 7.5 0 0119.5 10.5c0 2.92-.556 5.709-1.568 8.268M5.742 6.364A7.465 7.465 0 004.5 10.5a7.464 7.464 0 01-1.15 3.993m1.989 3.559A11.209 11.209 0 008.25 10.5a3.75 3.75 0 117.5 0c0 .527-.021 1.049-.064 1.565M12 10.5a14.94 14.94 0 01-3.6 9.75m6.633-4.596a18.666 18.666 0 01-2.485 5.33" />
                                            </svg>
                                        </div>
                                        <div className="flex-auto">
                                            <div className="block font-semibold text-gray-900" onClick={() => { toggleDropdown('settings') }}>
                                                Generation History
                                                <span className="absolute inset-0"></span>
                                            </div>
                                            <p className="mt-1 text-gray-600">View all your AI-generated content so far.</p>
                                        </div>
                                    </div> */}
                                    {/* <div className="group relative flex items-center gap-x-6 rounded-lg p-4 text-sm leading-6 hover:bg-gray-50">
                                        <div className="flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                                            <svg className="h-6 w-6 text-gray-600 group-hover:text-indigo-600" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
                                            </svg>
                                        </div>
                                        <div className="flex-auto">
                                            <div className="block font-semibold text-gray-900" onClick={() => { toggleDropdown('settings') }}>
                                                Automations
                                                <span className="absolute inset-0"></span>
                                            </div>
                                            <p className="mt-1 text-gray-600">Build strategic funnels that will convert</p>
                                        </div>
                                    </div> */}
                                </div>
                                <div className="grid grid-cols-2 divide-x divide-gray-900/5 bg-gray-50" onClick={() => { toggleDropdown('settings') }}>
                                    <div className="flex items-center justify-center gap-x-2.5 p-3 text-sm font-semibold leading-6 text-gray-900 hover:bg-gray-100">
                                        <svg className="h-5 w-5 flex-none text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                            <path fillRule="evenodd" d="M2 10a8 8 0 1116 0 8 8 0 01-16 0zm6.39-2.908a.75.75 0 01.766.027l3.5 2.25a.75.75 0 010 1.262l-3.5 2.25A.75.75 0 018 12.25v-4.5a.75.75 0 01.39-.658z" clipRule="evenodd" />
                                        </svg>
                                        Watch demo
                                    </div>
                                    <div className="flex items-center justify-center gap-x-2.5 p-3 text-sm font-semibold leading-6 text-gray-900 hover:bg-gray-100" onClick={handleLogOut}>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="h-5 w-5 flex-none text-gray-400">
                                            <path fillRule="evenodd" d="M16.5 3.75a1.5 1.5 0 0 1 1.5 1.5v13.5a1.5 1.5 0 0 1-1.5 1.5h-6a1.5 1.5 0 0 1-1.5-1.5V15a.75.75 0 0 0-1.5 0v3.75a3 3 0 0 0 3 3h6a3 3 0 0 0 3-3V5.25a3 3 0 0 0-3-3h-6a3 3 0 0 0-3 3V9A.75.75 0 1 0 9 9V5.25a1.5 1.5 0 0 1 1.5-1.5h6ZM5.78 8.47a.75.75 0 0 0-1.06 0l-3 3a.75.75 0 0 0 0 1.06l3 3a.75.75 0 0 0 1.06-1.06l-1.72-1.72H15a.75.75 0 0 0 0-1.5H4.06l1.72-1.72a.75.75 0 0 0 0-1.06Z" clipRule="evenodd" />
                                        </svg>
                                        Log Out
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </nav>
            {/* Mobile Menu */}
            <div className={`lg:hidden ${isMobileMenuOpen ? 'block' : 'hidden'}`} role="dialog" aria-modal="true">
                <div className="fixed inset-0 z-10"></div>
                <div className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
                    <div className="flex items-center justify-between">
                        <div className="-m-1.5 p-1.5 inline-flex items-center gap-2" onClick={() => { navigate('/'); toggleMobileMenu() }}>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                                <path fillRule="evenodd" d="M20.599 1.5c-.376 0-.743.111-1.055.32l-5.08 3.385a18.747 18.747 0 0 0-3.471 2.987 10.04 10.04 0 0 1 4.815 4.815 18.748 18.748 0 0 0 2.987-3.472l3.386-5.079A1.902 1.902 0 0 0 20.599 1.5Zm-8.3 14.025a18.76 18.76 0 0 0 1.896-1.207 8.026 8.026 0 0 0-4.513-4.513A18.75 18.75 0 0 0 8.475 11.7l-.278.5a5.26 5.26 0 0 1 3.601 3.602l.502-.278ZM6.75 13.5A3.75 3.75 0 0 0 3 17.25a1.5 1.5 0 0 1-1.601 1.497.75.75 0 0 0-.7 1.123 5.25 5.25 0 0 0 9.8-2.62 3.75 3.75 0 0 0-3.75-3.75Z" clipRule="evenodd" />
                            </svg>
                            <span className='text-lime-400  text-3xl font-bold'>
                                <span className='text-black'>restyled</span>.ai
                            </span>
                        </div>
                        <button type="button" onClick={toggleMobileMenu} className="-m-2.5 rounded-md p-2.5 text-gray-700">
                            <span className="sr-only">Close menu</span>
                            <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </button>
                    </div>
                    <div className="mt-6 flow-root">
                        <div className="-my-6 divide-y divide-gray-500/10">
                            <div className="space-y-2 py-6">
                                <div className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50" onClick={() => { navigate('/'); toggleMobileMenu() }}>Home</div>
                                <div className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50" onClick={() => { navigate('/ai-image-restyle'); toggleMobileMenu() }}>AI Image Restyle</div>
                                <div className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50" onClick={() => { navigate('/shop'); toggleMobileMenu() }}>Shop</div>
                                <div className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50" onClick={() => { navigate('/track-order'); toggleMobileMenu() }}>Track Order</div>
                            </div>
                            <div className="py-6">
                                {/* Settings Dropdown */}
                                <div className="-mx-3">
                                    <button type="button" onClick={() => { currentUser ? toggleDropdown('settings') : toggleLoginForm() }} className="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50" aria-controls="disclosure-1" aria-expanded="false">
                                        {currentUser ?
                                            <>
                                                {currentUser?.displayName}
                                                <svg className="h-5 w-5 flex-none text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                    <path fillRule="evenodd" d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z" clipRule="evenodd" />
                                                </svg>
                                            </> :
                                            <>
                                                Log in
                                                <span aria-hidden="true">&rarr;</span>
                                            </>
                                        }
                                    </button>
                                    {currentUser && dropdownStates.settings && (
                                        <div className="mt-2 space-y-2" id="disclosure-1">
                                            <div className="inline-flex items-center gap-2 rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-50 capitalize" onClick={() => { navigate('/'); toggleDropdown('settings'); toggleMobileMenu(); toggleTopUpCredits() }}>
                                                {currentUser?.subscriptionPlan} Plan
                                                <div className='text-xs clear-start px-2 py-1 rounded-md bg-lime-100 font-medium w-fit'>{currentUser?.creditsAvailable} Credits Left</div>
                                            </div>
                                            <div className="block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-50" onClick={() => { toggleDropdown('settings'); toggleMobileMenu(); navigate('/user-generations') }}>Generation History</div>
                                            {currentUser.userRole === 'admin' && (<div className="block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-50" onClick={() => { navigate('/dashboard'); toggleDropdown('settings'); toggleMobileMenu() }}>Admin Panel</div>)}
                                            {/* <div className="block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-50" onClick={() => { navigate('/'); toggleDropdown('settings'); toggleMobileMenu() }}>Integrations</div> */}
                                            {/* <div className="block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-50" onClick={() => { navigate('/'); toggleDropdown('settings'); toggleMobileMenu() }}>Automations</div> */}
                                            {/* <div className="block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-50" onClick={() => { navigate('/'); toggleDropdown('settings'); toggleMobileMenu() }}>Watch demo</div> */}
                                            <div className="block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-50" onClick={() => { handleLogOut(); toggleDropdown('settings'); toggleMobileMenu() }}>Log Out</div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    )
}