import React, { useState } from 'react'
import Lottie from 'lottie-react';
import lottieData from '../assets/login-lottie.json'
import googleIcon from '../assets/google.png';
import { useAuthenticate } from '../contexts/AuthenticateContext';

export default function Authenticate() {
    // Use the useAuthenticate hook to access authentication context
    const { authStatus, toggleLoginForm, signInWithGoogle, signInWithEmail, createUserWithEmail, error } = useAuthenticate();

    const handleSignInWithGoogle = async () => {
        await signInWithGoogle();
    }

    const [credentials, setCredentials] = useState({ displayName: '', email: '', password: '' });

    const handleCredentialsChange = (e) => {
        const { name, value } = e.target;
        setCredentials(prevCredentials => ({
            ...prevCredentials,
            [name]: value
        }));
    };

    const handleSignInWithEmail = async () => {
        await signInWithEmail(credentials.email, credentials.password);
    };

    const handleCreateUser = async () => {
        await createUserWithEmail(credentials.displayName, credentials.email, credentials.password);
    }

    const [authForm, setAuthForm] = useState('signin');

    return (
        <div className='fixed inset-0 bg-black bg-opacity-70 z-50 flex items-center justify-center'>
            <div className='grid grid-cols-1 xl:grid-cols-2 bg-white w-[90%] sm:w-[70%] lg:w-[50%] rounded-2xl overflow-hidden'>
                <Lottie animationData={lottieData} className='hidden xl:block' />
                <div className='relative overflow-hidden'>
                    <div className='w-6 h-6 rounded-full bg-black text-white font-bold flex items-center justify-center cursor-pointer absolute right-2 top-2 z-10' onClick={toggleLoginForm}>X</div>
                    <div className={`relative overflow-hidden flex`}>
                        <div className={`p-6 w-fit shrink-0 bg-white ${authForm === 'signin' ? 'translate-x-0 opacity-100 order-1' : 'translate-x-full opacity-0 order-2'} transition-all ease-in-out duration-500`}>
                            <h2 className='mt-4 text-xl font-bold inline-flex items-center gap-2'>
                                Sign In
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                                    <path fillRule="evenodd" d="M12 1.5a5.25 5.25 0 0 0-5.25 5.25v3a3 3 0 0 0-3 3v6.75a3 3 0 0 0 3 3h10.5a3 3 0 0 0 3-3v-6.75a3 3 0 0 0-3-3v-3c0-2.9-2.35-5.25-5.25-5.25Zm3.75 8.25v-3a3.75 3.75 0 1 0-7.5 0v3h7.5Z" clipRule="evenodd" />
                                </svg>
                            </h2>
                            <p className='mt-4 text-xs text-gray-400'>Sign in to access the full suite of features offered by Artify.ai.</p>
                            <input type="email" name="email" className='mt-4 w-full px-2 py-3 bg-slate-100 rounded-lg outline-none border-none' placeholder='Email' value={credentials.email} onChange={handleCredentialsChange} />
                            <input type="password" name="password" className='mt-4 w-full px-2 py-3 bg-slate-100 rounded-lg outline-none border-none' placeholder='Password' value={credentials.password} onChange={handleCredentialsChange} />
                            <button className="mt-4 w-full p-4 rounded-xl font-bold cursor-pointer inline-flex items-center justify-center gap-4 border-2 border-secondary-900 text-black" onClick={handleSignInWithEmail}>
                                Sign in
                                {authStatus.isLoading && authStatus.loginMethod === 'Email' && (
                                    <div role="status">
                                        <svg aria-hidden="true" className="inline w-5 h-5 text-white animate-spin dark:text-black fill-white dark:fill-white" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                                        </svg>
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                )}
                            </button>
                            <p className='mt-4 text-center text-xs'>New User? <span className='text-blue-600 cursor-pointer' onClick={() => { setAuthForm(authForm === 'signin' ? 'signup' : 'signin') }}>Create Account</span></p>
                            <button className="mt-4 w-full p-4 rounded-xl font-bold cursor-pointer inline-flex items-center justify-center gap-4 border-2 border-secondary-900 text-black" onClick={handleSignInWithGoogle}>
                                Sign in with Google
                                {authStatus.isLoading && authStatus.loginMethod === 'Google' ? (
                                    <div role="status">
                                        <svg aria-hidden="true" className="inline w-5 h-5 text-white animate-spin dark:text-black fill-white dark:fill-white" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                                        </svg>
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                ) : <img src={googleIcon} alt="" className='w-6' />}
                            </button>
                            <p className='mt-4 text-[10px] text-red-600 text-center'>{error}</p>
                        </div>
                        <div className={`p-6 w-fit shrink-0 bg-white ${authForm === 'signup' ? 'translate-y-0 opacity-100 order-1' : 'translate-y-full opacity-0 order-2'} transition-all ease-in-out duration-500`}>
                            <h2 className='mt-4 text-xl font-bold inline-flex items-center gap-2'>
                                Sign Up
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                                    <path fillRule="evenodd" d="M12 1.5a5.25 5.25 0 0 0-5.25 5.25v3a3 3 0 0 0-3 3v6.75a3 3 0 0 0 3 3h10.5a3 3 0 0 0 3-3v-6.75a3 3 0 0 0-3-3v-3c0-2.9-2.35-5.25-5.25-5.25Zm3.75 8.25v-3a3.75 3.75 0 1 0-7.5 0v3h7.5Z" clipRule="evenodd" />
                                </svg>
                            </h2>
                            <p className='mt-4 text-xs text-gray-400'>Sign up to unlock the full suite of features offered by Artify.ai.</p>
                            <input type="text" name="displayName" className='mt-4 w-full px-2 py-3 bg-slate-100 rounded-lg outline-none border-none' placeholder='Full Name' value={credentials.displayName} onChange={handleCredentialsChange} />
                            <input type="email" name="email" className='mt-4 w-full px-2 py-3 bg-slate-100 rounded-lg outline-none border-none' placeholder='Email' value={credentials.email} onChange={handleCredentialsChange} />
                            <input type="password" name="password" className='mt-4 w-full px-2 py-3 bg-slate-100 rounded-lg outline-none border-none' placeholder='Password' value={credentials.password} onChange={handleCredentialsChange} />
                            <button className="mt-4 w-full p-4 rounded-xl font-bold cursor-pointer inline-flex items-center justify-center gap-4 border-2 border-secondary-900 text-black" onClick={handleCreateUser}>
                                Create Account
                                {authStatus.isLoading && authStatus.loginMethod === 'Email' && (
                                    <div role="status">
                                        <svg aria-hidden="true" className="inline w-5 h-5 text-white animate-spin dark:text-black fill-white dark:fill-white" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                                        </svg>
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                )}
                            </button>
                            <p className='mt-4 text-center text-xs'>Already have account? <span className='text-blue-600 cursor-pointer' onClick={() => { setAuthForm(authForm === 'signin' ? 'signup' : 'signin') }}>Sign In</span></p>
                            <p className='mt-4 text-[10px] text-red-600 text-center'>{error}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}