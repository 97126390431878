import React from 'react'
import { useAuthenticate } from '../contexts/AuthenticateContext';
import { useRestyledImages } from '../contexts/RestyledImagesContext';
import { useLocation, useNavigate } from 'react-router-dom';

export default function UserGenerations({ setRestyledImageKey }) {
    const navigate = useNavigate();
    const location = useLocation();

    // Use the useAuthenticate hook to access authentication context
    const { authStatus, currentUser } = useAuthenticate();

    // Use the useRestyledImages() hook to access the restyledImages array
    const { restyledImages } = useRestyledImages();

    return (!authStatus.isLoading && currentUser && location?.pathname !== '/' && (
        <div className={`w-full container mx-auto ${location.pathname === '/user-generations' ? 'min-h-[550px] mt-6 xl:mt-12 px-6 xl:px-0' : ''}`}>
            <h2 className='text-xl xl:text-3xl font-bold inline-flex items-center gap-4'>Generation History</h2>
            <p className='mt-4 text-xs xl:text-sm text-gray-600'>Here are all the images you restyled.</p>
            <div className='mt-4 grid grid-cols-[repeat(auto-fill,minmax(100px,1fr))] justify-between gap-4 md:grid-cols-[repeat(auto-fill,minmax(140px,1fr))] lg:gap-8'>
                {restyledImages && Object.keys(restyledImages).length > 0 && Object.entries(restyledImages)
                    .filter(([_, image]) => image.restyledImageUrl) // Filter images with restyledImageUrl
                    .map(([key, image]) => (
                        <div key={key} className=''>
                            <div className="group relative overflow-hidden rounded-[12px] md:rounded-[16px] aspect-[190/256] bg-cover bg-center" style={{ backgroundImage: `url(${image.restyledImageUrl})` }}>
                                <div className="absolute flex h-20 w-full flex-col justify-end bg-gradient-to-t from-black/60 bottom-0">
                                    <div className="absolute left-0 right-0 flex items-center justify-end p-2 gap-2 translate-y-full transition-all duration-300 group-hover:translate-y-0">
                                        <button type="button" className="w-full font-normal bg-lime-400 text-black text-sm py-3 rounded-3xl inline-flex items-center justify-center gap-2" onClick={() => location.pathname !== '/user-generations' ? (setRestyledImageKey(key), window.scrollTo(0, 0)) : navigate('/ai-image-restyle', { state: { restyledImageKey: key } })}>
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-4 h-4">
                                                <path d="M12 15a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" />
                                                <path fillRule="evenodd" d="M1.323 11.447C2.811 6.976 7.028 3.75 12.001 3.75c4.97 0 9.185 3.223 10.675 7.69.12.362.12.752 0 1.113-1.487 4.471-5.705 7.697-10.677 7.697-4.97 0-9.186-3.223-10.675-7.69a1.762 1.762 0 0 1 0-1.113ZM17.25 12a5.25 5.25 0 1 1-10.5 0 5.25 5.25 0 0 1 10.5 0Z" clipRule="evenodd" />
                                            </svg>
                                            Select
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className='mt-2 w-fit text-[9px] text-gray-900 p-1 border-2 border-black rounded bg-lime-400'>Credits Used: {image?.creditsUsed}</div>
                        </div>
                    ))}
            </div>
        </div >
    ))
}