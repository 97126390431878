import React, { useMemo, useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import Authenticate from '../components/Authenticate';
import TopUpCredits from '../components/TopUpCredits';
// Contexts
import { useAuthenticate } from '../contexts/AuthenticateContext';
import { useRestyledImages } from '../contexts/RestyledImagesContext';
import { useTopUpCredits } from '../contexts/TopUpCreditsContext';

// Other Imports
import Lottie from 'lottie-react';
import Cart from '../components/Cart';
import UserGenerations from '../components/UserGenerations';
import photoUploadLottie from '../assets/photo-upload-lottie.json';
import { motion } from 'framer-motion';

export default function PhotoStyler() {
    const navigate = useNavigate();
    const location = useLocation();

    const { isTopUpCreditsVisible, toggleTopUpCredits } = useTopUpCredits();

    // Use the useAuthenticate hook to access authentication context
    const { isLoginFormVisible, currentUser, toggleLoginForm } = useAuthenticate();

    // Use the useRestyledImages() hook to access the restyledImages array
    const { restyledImages } = useRestyledImages();

    const [expandFilters, setExpandFilters] = useState(false);

    const toggleExpandFilters = () => {
        if (window.innerWidth < 1280) {
            setExpandFilters(prevExpandFilters => !prevExpandFilters);
            if (expandFilters) {
                window.scrollTo({ top: 0, behavior: 'smooth' }); // Scrolls to the top with smooth behavior
            }
        }
    };
    

    const availableFilter = useMemo(() => [
        { filterID: 'Vx2sa2A', filterName: 'Pixar Style', filterImage: 'https://firebasestorage.googleapis.com/v0/b/image-restyle.appspot.com/o/assets%2F1.jpg?alt=media&token=906f27b8-d868-46f1-b04d-5a5c3426d735' },
        { filterID: 'Lp9hRc', filterName: 'Modern Anime', filterImage: 'https://firebasestorage.googleapis.com/v0/b/image-restyle.appspot.com/o/assets%2F2.jpg?alt=media&token=9f39947e-2ab3-485a-9bda-7ef8133bf432' },
        { filterID: 'Zt4rG5', filterName: 'Grand Theft Auto', filterImage: 'https://firebasestorage.googleapis.com/v0/b/image-restyle.appspot.com/o/assets%2F3.jpg?alt=media&token=e2679dd0-0527-4878-ab28-ffe7602f03b8' },
        { filterID: 'Im8pJb', filterName: 'Cybertech', filterImage: 'https://firebasestorage.googleapis.com/v0/b/image-restyle.appspot.com/o/assets%2F4.jpg?alt=media&token=ed0ad6d1-9f52-4374-9b79-c86f2ce811f2' },
        { filterID: 'Mv7Uq9', filterName: 'Oil Painting', filterImage: 'https://firebasestorage.googleapis.com/v0/b/image-restyle.appspot.com/o/assets%2F5.jpg?alt=media&token=d8f4cd35-f6bc-47bd-b534-af4e2655cb3f' },
        { filterID: 'Yw8KpF', filterName: 'Oldschool Comic', filterImage: 'https://firebasestorage.googleapis.com/v0/b/image-restyle.appspot.com/o/assets%2F6.jpg?alt=media&token=a898e8d2-d5e0-479d-86dc-b27e37e4bb20' },
        { filterID: 'Bn1pRd', filterName: 'Retro', filterImage: 'https://firebasestorage.googleapis.com/v0/b/image-restyle.appspot.com/o/assets%2F7.jpg?alt=media&token=03ce3319-925f-4526-aca2-79912db0f4e5' },
        { filterID: 'Tk2wRc', filterName: 'Glowwave', filterImage: 'https://firebasestorage.googleapis.com/v0/b/image-restyle.appspot.com/o/assets%2F8.jpg?alt=media&token=32c3d663-8435-4644-b8a8-a53b7eab664a' },
        { filterID: 'Lg9Tq1', filterName: 'Complex Art', filterImage: 'https://firebasestorage.googleapis.com/v0/b/image-restyle.appspot.com/o/assets%2F9.jpg?alt=media&token=c4a77464-86dc-4999-8228-c9aec09c9f2a' },
        { filterID: 'Dt5pIb', filterName: 'Anime', filterImage: 'https://firebasestorage.googleapis.com/v0/b/image-restyle.appspot.com/o/assets%2F10.jpg?alt=media&token=192c2882-15ab-4933-a223-c195718e5588' },
        { filterID: 'An7eW1', filterName: '3D Mascot', filterImage: 'https://firebasestorage.googleapis.com/v0/b/image-restyle.appspot.com/o/assets%2F11.jpg?alt=media&token=c61b80e7-815a-4c10-9a31-90b299f624d4' },
        { filterID: 'Al0eWf', filterName: 'Ancient Rich', filterImage: 'https://firebasestorage.googleapis.com/v0/b/image-restyle.appspot.com/o/assets%2F12.jpg?alt=media&token=4803941e-412a-445d-9deb-cc3ce2ab02a5' },
        { filterID: 'Hn1goId', filterName: 'Humanoid', filterImage: 'https://firebasestorage.googleapis.com/v0/b/image-restyle.appspot.com/o/assets%2F13.jpg?alt=media&token=a4f7dcb3-46e8-4ed8-9885-ec8bcdfdb439' },
        { filterID: 'El1xf', filterName: 'Elf', filterImage: 'https://cdn.leonardo.ai/users/b8a384fc-fb0c-4c50-bf8f-77fb1730d593/generations/5a268588-4335-49fd-b574-15e9f6124d55/variations/alchemyrefiner_alchemymagic_1_5a268588-4335-49fd-b574-15e9f6124d55_0.jpg?w=512' },
    ], []);

    const [activeFilter, setActiveFilter] = useState(availableFilter[0]);

    const [selectedFile, setSelectedFile] = useState(null);
    const [previewUrl, setPreviewUrl] = useState(null);

    const handleRestyleImage = async (file) => {
        try {
            if (!file) {
                console.error('No file selected.');
                return;
            }

            isUploadingImage(true);

            const formData = new FormData();
            formData.append('image', file);
            formData.append('uid', currentUser.uid);
            formData.append('filterID', activeFilter.filterID);

            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/images/restyle`, {
                method: 'POST',
                headers: {
                    // Add any additional headers if needed
                },
                body: formData,
            });

            if (response.ok) {
                const result = await response.json();
                setRestyledImageKey(result.imageKey);
            } else {
                console.error('Error:', response.statusText);
                // Optionally, handle error and update state accordingly
            }
        } catch (error) {
            console.error('Error:', error.message);
            // Optionally, handle error and update state accordingly
        } finally {
            // Set uploadingImage state to false to hide the spinner, regardless of success or error
            isUploadingImage(false);
            setSelectedFile(null);
        }
    };

    const handleFileChange = async (e) => {
        if (!currentUser) {
            // Assuming toggleLoginForm is a function that toggles the login form visibility
            toggleLoginForm();
            return;
        }
    
        const file = e.target.files[0];
    
        if (file) {
            const fileType = file.type;
            if (!fileType.startsWith('image/')) {
                alert('Please select an image file.');
                return;
            }
    
            const img = new Image();
            img.onload = () => {
                if (img.width >= 500 && img.height >= 500) {
                    const reader = new FileReader();
                    reader.onloadend = () => {
                        setPreviewUrl(reader.result);
                        setSelectedFile(file);
                    };
                    reader.readAsDataURL(file);
                } else {
                    alert('Image dimensions should be at least 500px by 500px.');
                }
            };
    
            img.src = URL.createObjectURL(file);
        }
    };
    
    const [uploadingImage, isUploadingImage] = useState(null);
    const [restyledImageKey, setRestyledImageKey] = useState(location?.state?.restyledImageKey);

    useEffect(() => {
        if (restyledImages && restyledImageKey && restyledImages[restyledImageKey]?.restyledImageUrl) {
            setPreviewUrl(restyledImages[restyledImageKey].restyledImageUrl);
            setSelectedFile(null);
        }
    }, [restyledImages, restyledImageKey]);

    const printRestyledImage = () => {
        navigate('/shop', { state: { restyledImageKey: restyledImageKey } });
    }

    function downloadImage(restyledImages, restyledImageKey) {
        // Get the URL of the restyled image
        const imageUrl = currentUser?.subscriptionPlan === 'free' ? restyledImages[restyledImageKey].restyledImageUrlWithWatermark : restyledImages[restyledImageKey].restyledImageUrl;

        // Create a new XMLHttpRequest
        const xhr = new XMLHttpRequest();
        xhr.open('GET', imageUrl, true);
        xhr.responseType = 'blob';

        xhr.onload = function () {
            if (this.status === 200) {
                // Create a blob from the response
                const blob = new Blob([this.response], { type: 'image/jpeg' });

                // Generate a timestamp for the filename
                const timestamp = new Date().toISOString().replace(/[-T:Z]/g, '');

                // Create a temporary link element
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);

                // Generate the filename based on current date and time
                const filename = `restyled_image_${timestamp}.jpg`;

                // Set the download attribute with the dynamic filename
                link.download = filename;

                // Append the link to the document body and trigger the click event
                document.body.appendChild(link);
                link.click();

                // Remove the link from the document body
                document.body.removeChild(link);
            }
        };

        // Send the request
        xhr.send();
    }

    return (
        <div>
            {isLoginFormVisible && (
                <Authenticate />
            )}

            {isTopUpCreditsVisible && (
                <TopUpCredits />
            )}
            <div className='w-full container mx-auto px-6 xl:px-0'>

                <div className='w-full grid grid-cols-1 xl:grid-cols-2 items-start justify-between gap-4 mt-4'>
                    <motion.div viewport={{ once: true }} initial={{ opacity: 0, x: -100 }} whileInView={{ opacity: 1, x: 0 }} transition={{ ease: "easeInOut", duration: 1 }}>
                        <div className={`relative w-full h-[400px] xl:h-[550px] p-8 border-2 border-dashed rounded-2xl flex items-center justify-center cursor-pointer transition-all ease-in-out duration-500 overflow-hidden ${previewUrl ? '' : 'hover:bg-slate-50 hover:border-lime-400'}`} style={previewUrl ? { backgroundImage: `url('${previewUrl}')`, backgroundRepeat: 'no-repeat', backgroundPosition: 'center' } : {}} onClick={() => { location.pathname !== '/' ? (document.getElementById('fileInput').click()) : navigate('/ai-image-restyle') }}>
                            {!previewUrl && !restyledImageKey && (
                                <div className='w-full h-full flex flex-col items-center gap-4'>
                                    <Lottie animationData={photoUploadLottie} className='h-3/5' />
                                    <p className='text-[10px] text-gray-400 text-center h-1/5'>
                                        Upload one photo individually and utilize AI filters to witness enchantment.
                                    </p>
                                    <button className='outline-none px-4 py-2 xl:px-6 xl:py-4 text-black font-medium bg-lime-400 border-2 border-black hover:scale-95 hover:bg-lime-400 hover:text-black hover:transition-all ease-in-out duration-300 rounded-full inline-flex items-center gap-2 '>
                                        {location.pathname !== '/' ? 'Upload Photo' : 'Try It Now'}
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                                            <path fillRule="evenodd" d="M12 1.5a.75.75 0 01.75.75V4.5a.75.75 0 01-1.5 0V2.25A.75.75 0 0112 1.5zM5.636 4.136a.75.75 0 011.06 0l1.592 1.591a.75.75 0 01-1.061 1.06l-1.591-1.59a.75.75 0 010-1.061zm12.728 0a.75.75 0 010 1.06l-1.591 1.592a.75.75 0 01-1.06-1.061l1.59-1.591a.75.75 0 011.061 0zm-6.816 4.496a.75.75 0 01.82.311l5.228 7.917a.75.75 0 01-.777 1.148l-2.097-.43 1.045 3.9a.75.75 0 01-1.45.388l-1.044-3.899-1.601 1.42a.75.75 0 01-1.247-.606l.569-9.47a.75.75 0 01.554-.68zM3 10.5a.75.75 0 01.75-.75H6a.75.75 0 010 1.5H3.75A.75.75 0 013 10.5zm14.25 0a.75.75 0 01.75-.75h2.25a.75.75 0 010 1.5H18a.75.75 0 01-.75-.75zm-8.962 3.712a.75.75 0 010 1.061l-1.591 1.591a.75.75 0 11-1.061-1.06l1.591-1.592a.75.75 0 011.06 0z" clipRule="evenodd" />
                                        </svg>
                                    </button>
                                </div>
                            )}
                            {previewUrl && (
                                <>
                                    <div className='backdrop-blur-lg bg-white/30 w-full h-full absolute inset-0 z-10'></div>
                                    <img className="absolute inset-0 z-20 shadow-2xl object-contain min-h-0 w-auto h-auto sm:max-h-[90%] rounded-lg max-h-[90%] max-w-[90%] self-center justify-self-center" src={previewUrl} alt="Selected Preview" />
                                </>
                            )}
                            <input type="file" id="fileInput" accept="image/*" className="hidden" onChange={handleFileChange} />
                        </div>
                        {previewUrl && restyledImageKey && restyledImages?.[restyledImageKey]?.restyledImageUrl && (
                            <div className='flex items-center justify-end space-x-6 pt-4'>
                                <button className='border-2 border-black px-4 py-2 rounded-3xl' onClick={() => downloadImage(restyledImages, restyledImageKey)}>Download</button>
                                <button className='border-2 border-black bg-lime-400 px-4 py-2 rounded-3xl' onClick={printRestyledImage}>Print On Products</button>
                            </div>
                        )}
                    </motion.div>

                    <motion.div viewport={{ once: true }} initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} transition={{ ease: "easeInOut", duration: 1, delay: 0.6 }}>
                        <div className={`xl:max-h-[550px] relative ${location.pathname !== '/' ? 'xl:overflow-y-auto hide-scrollbar' : 'overflow-hidden'}`}>
                            <div className="flex items-center mb-2" onClick={toggleExpandFilters}>
                                <div className="flex-1 font-medium text-lg inline-flex items-center gap-2">
                                    Select style
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-4 h-4">
                                        <path d="M12 9a3.75 3.75 0 1 0 0 7.5A3.75 3.75 0 0 0 12 9Z" />
                                        <path fillRule="evenodd" d="M9.344 3.071a49.52 49.52 0 0 1 5.312 0c.967.052 1.83.585 2.332 1.39l.821 1.317c.24.383.645.643 1.11.71.386.054.77.113 1.152.177 1.432.239 2.429 1.493 2.429 2.909V18a3 3 0 0 1-3 3h-15a3 3 0 0 1-3-3V9.574c0-1.416.997-2.67 2.429-2.909.382-.064.766-.123 1.151-.178a1.56 1.56 0 0 0 1.11-.71l.822-1.315a2.942 2.942 0 0 1 2.332-1.39ZM6.75 12.75a5.25 5.25 0 1 1 10.5 0 5.25 5.25 0 0 1-10.5 0Zm12-1.5a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Z" clipRule="evenodd" />
                                    </svg>
                                </div>
                            </div>
                            <div className={`${expandFilters ? 'grid' : 'hidden'} xl:grid grid-cols-[1fr_1fr] justify-between gap-2 md:grid-cols-[repeat(auto-fill,minmax(100px,1fr))] xl:grid-cols-[repeat(auto-fill,minmax(140px,1fr))] flex-1`} style={{ gridAutoRows: 'min-content' }}>
                                {availableFilter.map((filter) => (
                                    <div key={filter.filterID} className={`w-full hover:bg-grays80/20 rounded-[16px] md:rounded-[20px] relative border-2 p-0.5 cursor-pointer ${activeFilter?.filterID === filter.filterID ? 'border-lime-400' : ''}`} onClick={() => { setActiveFilter(filter); toggleExpandFilters() }}>
                                        <div className="group/card relative overflow-hidden md:rounded-[16px] aspect-[190/256] rounded-[16px]">
                                            <picture>
                                                <img
                                                    src={filter.filterImage}
                                                    poster={filter.filterImage}
                                                    className="pointer-events-none h-full w-full object-cover"
                                                    alt={filter.filterName}
                                                />
                                            </picture>
                                            <div className="absolute flex h-20 w-full flex-col justify-end bg-gradient-to-t from-black/60 bottom-0">
                                                <div className="absolute overflow-hidden overflow-ellipsis whitespace-nowrap text-white font-manrope text-xs font-normal xl:text-md xl:font-bold bottom-3 left-3 right-3">
                                                    {filter.filterName}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div style={{ backgroundImage: 'linear-gradient(to bottom, #0000, #ffff)' }} className="hidden xl:block pointer-events-none sticky -bottom-1 h-14 w-full"></div>
                        </div>
                        <div className="w-full flex items-center justify-between bg-white mt-4">
                            <div className="flex items-center gap-2" onClick={toggleExpandFilters}>
                                <img src={activeFilter?.filterImage} alt="" className="object-cover pointer-events-none h-[40px] w-[40px] rounded-lg border-4 border-lime-400" />
                                <div className="inline-flex flex-1 flex-col text-xs">
                                    <div className="text-gray-400">Selected:</div>
                                    <div className="truncate">{activeFilter?.filterName}</div>
                                </div>
                            </div>
                            <button className={`w-full max-w-[160px] py-2 bg-lime-400 text-black font-medium border-2 border-black rounded-xl inline-flex items-center gap-2 justify-center disabled:bg-lime-200 disabled:cursor-not-allowed`} disabled={!currentUser || !selectedFile || uploadingImage} onClick={() => { (currentUser && selectedFile && currentUser.creditsAvailable > 0) ? handleRestyleImage(selectedFile) : toggleTopUpCredits() }}>
                                Restyle
                                {selectedFile && uploadingImage && (
                                    <div role="status">
                                        <svg aria-hidden="true" className="inline w-4 h-4 text-lime-400 animate-spin fill-white" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                                        </svg>
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                )}
                            </button>
                        </div>
                    </motion.div>
                </div>

                {restyledImages && Object.keys(restyledImages).length > 0 && (
                    // My Designs Section
                    <motion.div viewport={{ once: true }} className='mt-12' initial={{ opacity: 0, y: -100 }} whileInView={{ opacity: 1, y: 0 }} transition={{ ease: "easeInOut", duration: 1 }}>
                        <UserGenerations setRestyledImageKey={setRestyledImageKey} />
                    </motion.div>
                )}

                {/* Cart */}
                <Cart />

            </div>
        </div >
    )
}