import React, { useEffect, useState } from 'react';
import { useCart } from '../contexts/CartProvider';
import { fabric } from 'fabric';
import { loadStripe } from '@stripe/stripe-js';

const Cart = () => {
    const { cart, removeFromCart, updateQuantity, cartVisibility, toggleCartVisibility } = useCart();
    const [variantDetails, setVariantDetails] = useState([]);
    useEffect(() => {
        if (cart.length > 0) {
            const fetchVariantDetails = async () => {
                const details = await Promise.all(
                    cart.map(async (item) => {
                        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/products/variants/${item.variantId}`);
                        const data = await response.json();
                        // Return variant data along with canvasData
                        const variant = { ...data.variant, size: item.size, quantity: item.quantity };

                        // Create a Promise to load JSON data into the canvas
                        return new Promise((resolve) => {
                            // Create a temporary canvas element
                            var canvas = new fabric.Canvas();

                            // Set canvas dimensions
                            canvas.setDimensions({ width: 500, height: 500 });

                            // Load JSON data into the canvas
                            canvas.loadFromJSON(item.canvasData, function () {
                                // Render canvas content
                                canvas.renderAll();

                                // Get the SVG string with specified width and height
                                var svg = canvas.toSVG({ width: '100%', height: '100%' });

                                // Dispose of or delete the canvas
                                canvas.dispose(); // or canvas = null; depending on what you prefer

                                // Resolve the promise with variant and svg data
                                resolve({ variant: variant, svg: svg });
                            });
                        });
                    })
                );
                setVariantDetails(details);
            };

            fetchVariantDetails();
        }
    }, [cart]);

    useEffect(() => {
        if (cartVisibility) {
            // Disable body scrolling
            document.body.style.overflow = 'hidden';
        } else {
            // Re-enable body scrolling when cart is not visible
            document.body.style.overflow = 'auto';
        }

        // Clean up function to re-enable scrolling when component unmounts
        return () => {
            document.body.style.overflow = 'auto';
        };
    }, [cartVisibility]);

    const calculateSubtotal = () => {
        // Calculate subtotal logic here
        // For example, summing up the prices of items in the cart
        const subtotal = variantDetails.reduce((acc, variant) => acc + (variant.variant.price * variant.variant.quantity), 0);
        return subtotal.toFixed(2); // Assuming prices are in dollars and cents
    };

    const calculateTotal = () => {
        // Calculate total logic here
        // For example, adding subtotal and shipping costs
        const subtotal = parseFloat(calculateSubtotal());
        const shipping = 0; // Assuming no shipping charges
        const total = subtotal + shipping;
        return total.toFixed(2); // Assuming prices are in dollars and cents
    };

    const [isLoadingCheckout, setIsLoadingCheckout] = useState(false); // State to track loading progress
    const redirectToCheckout = async () => {
        try {
            setIsLoadingCheckout(true); // Set loading state to true when initiating checkout

            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/payments/create-checkout-session`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ lineItems: cart })
            });

            if (!response.ok) {
                throw new Error(`Network response was not ok: ${response.statusText}`);
            }

            const data = await response.json();
            const sessionId = data.id;

            const stripe = await loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);
            const { error } = await stripe.redirectToCheckout({
                sessionId: sessionId
            });

            if (error) {
                throw new Error(error.message);
            }
        } catch (error) {
            console.error(error);
            console.log(error.message || 'An error occurred while processing your request.');
        } finally {
            setIsLoadingCheckout(false); // Set loading state to false after checkout process is complete
        }
    };

    return (
        <div className={`fixed inset-0 bg-black bg-opacity-70 flex justify-end overflow-hidden ${cartVisibility ? 'visible opacity-100' : 'invisible opacity-0'}  transition-all ease-in-out duration-300 delay-300`}>
            <div className={`w-full xl:w-[400px] h-screen bg-white rounded-t-3xl xl:rounded-t-none xl:rounded-s-3xl overflow-hidden relative ${cartVisibility ? 'translate-x-0' : 'translate-x-full'} transition-all ease-in-out duration-300`}>
                {cartVisibility && (
                    <div className='w-full h-full overflow-y-auto hide-scrollbar p-4'>
                        <div className='flex items-center justify-between'>
                            <div className='text-md font-medium inline-flex items-center gap-2'>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                                    <path fillRule="evenodd" d="M7.5 6v.75H5.513c-.96 0-1.764.724-1.865 1.679l-1.263 12A1.875 1.875 0 0 0 4.25 22.5h15.5a1.875 1.875 0 0 0 1.865-2.071l-1.263-12a1.875 1.875 0 0 0-1.865-1.679H16.5V6a4.5 4.5 0 1 0-9 0ZM12 3a3 3 0 0 0-3 3v.75h6V6a3 3 0 0 0-3-3Zm-3 8.25a3 3 0 1 0 6 0v-.75a.75.75 0 0 1 1.5 0v.75a4.5 4.5 0 1 1-9 0v-.75a.75.75 0 0 1 1.5 0v.75Z" clipRule="evenodd" />
                                </svg>
                                Shopping Bag
                            </div>
                            <div className='w-6 h-6 bg-black text-white font-semibold flex items-center justify-center rounded-full cursor-pointer' onClick={toggleCartVisibility}>X</div>
                        </div>
                        <div className='mt-4 grid grid-cols-1 space-y-4'>
                            {cart.length === 0 ? (
                                <div className="text-center text-gray-500">Your cart is empty</div>
                            ) : (
                                <>
                                    {variantDetails?.map((variant, index) => (
                                        <div key={index} className='w-full h-24 rounded-xl grid grid-cols-8 space-x-2 p-2 border-2'>
                                            <div className='col-span-2 h-full overflow-hidden pointer-events-none' dangerouslySetInnerHTML={{ __html: variant?.svg }}></div>
                                            <div className='col-span-4 h-full flex flex-col gap-1'>
                                                <div className='text-sm truncate'>{variant?.variant?.name}</div>
                                                <div className='text-[12px]'>{variant?.variant?.color} / {variant?.variant?.size}</div>
                                                <div className='text-sm font-medium'>${variant?.variant?.price}</div>
                                            </div>
                                            <div className='col-span-2 h-full flex flex-col items-end justify-center gap-1'>
                                                <div className='w-20 grid grid-cols-3 gap-1 border rounded-lg overflow-hidden'>
                                                    <div className='w-full bg-slate-100 flex items-center justify-center cursor-pointer' onClick={() => { const q = parseInt(variant?.variant?.quantity); if (q > 1) updateQuantity(variant?.variant?.id, q - 1); }}>-</div>
                                                    <div className='text-center'>{variant?.variant?.quantity}</div>
                                                    <div className='w-full bg-slate-100 flex items-center justify-center cursor-pointer' onClick={() => { updateQuantity(variant?.variant?.id, parseInt(variant?.variant?.quantity) + 1) }}>+</div>
                                                </div>
                                                <button className='text-[10px] font-medium text-red-600 text-center w-full cursor-pointer' onClick={() => {
                                                    if (window.confirm('Do you want to remove this from cart?')) {
                                                        removeFromCart(variant?.variant?.id);
                                                    }
                                                }}>Remove</button>
                                            </div>
                                        </div>
                                    ))}
                                    {/* Order Summary */}
                                    <div className='mt-4'>
                                        <div className="bg-gray-100 p-4 rounded-xl">
                                            <h2 className="text-md font-semibold mb-2">Order Summary</h2>
                                            <div className="flex justify-between mb-2 text-xs">
                                                <span>Subtotal:</span>
                                                <span>${calculateSubtotal()}</span> {/* Assuming calculateSubtotal() calculates subtotal */}
                                            </div>
                                            <div className="flex justify-between mb-2 text-xs">
                                                <span>Shipping:</span>
                                                <span>$0.00</span> {/* Assuming no shipping charges */}
                                            </div>
                                            <div className="flex justify-between mb-2 text-xs">
                                                <span>Total:</span>
                                                <span>${calculateTotal()}</span> {/* Assuming calculateTotal() calculates total */}
                                            </div>
                                            <button className="bg-lime-400 border-2 border-black text-black px-4 py-2 rounded-3xl mt-4 w-full inline-flex items-center justify-center gap-2 font-medium" onClick={redirectToCheckout}>
                                                Checkout
                                                {isLoadingCheckout ?
                                                    <div role="status">
                                                        <svg aria-hidden="true" className="inline w-5 h-5 text-lime-400 animate-spin dark:text-black fill-lime-400 dark:fill-lime-400" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                                            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                                                        </svg>
                                                        <span className="sr-only">Loading...</span>
                                                    </div> :
                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                                                        <path fillRule="evenodd" d="M15.75 2.25H21a.75.75 0 0 1 .75.75v5.25a.75.75 0 0 1-1.5 0V4.81L8.03 17.03a.75.75 0 0 1-1.06-1.06L19.19 3.75h-3.44a.75.75 0 0 1 0-1.5Zm-10.5 4.5a1.5 1.5 0 0 0-1.5 1.5v10.5a1.5 1.5 0 0 0 1.5 1.5h10.5a1.5 1.5 0 0 0 1.5-1.5V10.5a.75.75 0 0 1 1.5 0v8.25a3 3 0 0 1-3 3H5.25a3 3 0 0 1-3-3V8.25a3 3 0 0 1 3-3h8.25a.75.75 0 0 1 0 1.5H5.25Z" clipRule="evenodd" />
                                                    </svg>
                                                }
                                            </button>
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Cart;
