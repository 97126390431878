import React, { useEffect, useRef } from 'react';
import { fabric } from 'fabric';

const DesignEditor = ({ productImage, printAreaData, designImage, text, exportCanvasData }) => {
  const canvasRef = useRef(null);
  const fabricCanvasRef = useRef(null);
  const printAreaRectRef = useRef(null);
  const primaryTextRef = useRef(null);
  const secondaryTextRef = useRef(null);

  function renderDesignImage() {
    const fabricCanvas = fabricCanvasRef.current;

    if (!fabricCanvas || !designImage) return;

    // Remove previous image objects
    fabricCanvas.getObjects('image').forEach((obj) => {
      fabricCanvas.remove(obj);
    });

    fabric.Image.fromURL(designImage, (img) => {
      const printArea = printAreaRectRef.current;
      const scaleFactor = printArea.width / img.width;

      img.scaleToWidth(printArea.width);
      img.scaleToHeight(img.height * scaleFactor);

      img.set({
        left: printArea.left,
        top: printArea.top,
        clipPath: new fabric.Rect({
          left: printArea.left,
          top: printArea.top,
          width: printArea.width,
          height: printArea.height,
          absolutePositioned: true
        })
      });
      fabricCanvas.add(img);
    }, { crossOrigin: 'anonymous' });
  }

  function renderPrimaryText() {
    const fabricCanvas = fabricCanvasRef.current;
    const printArea = printAreaRectRef.current;

    if (!fabricCanvas || !printArea || text.primary.text.length < 1) return;

    // Remove existing primary text object from the canvas
    if (primaryTextRef.current) {
      fabricCanvas.remove(primaryTextRef.current);
      primaryTextRef.current = null;
    }

    primaryTextRef.current = new fabric.Text(text.primary.text, {
      top: printArea.top + 5,
      fill: text.primary.color || 'black',
      fontSize: 36,
      fontFamily: text.primary.fontFamily || 'Arial',
      clipPath: new fabric.Rect({
        left: printArea.left,
        top: printArea.top,
        width: printArea.width,
        height: printArea.height,
        absolutePositioned: true
      }),
      selectable: true // Disable selection and interaction
    });
    primaryTextRef.current.set({
      left: printArea.left + (printArea.width - primaryTextRef.current.width) / 2,
    });
    primaryTextRef.current.bringToFront();
    fabricCanvas.add(primaryTextRef.current);
  }

  function renderSecondaryText() {
    const fabricCanvas = fabricCanvasRef.current;
    const printArea = printAreaRectRef.current;

    if (!fabricCanvas || !printArea || text.secondary.text.length < 1) return;

    // Remove existing secondary text object from the canvas
    if (secondaryTextRef.current) {
      fabricCanvas.remove(secondaryTextRef.current);
      secondaryTextRef.current = null;
    }

    secondaryTextRef.current = new fabric.Text(text.secondary.text, {
      top: printArea.top + 41, // Example: Offset from primary text
      fill: text.secondary.color || 'black',
      fontSize: 20,
      fontFamily: text.secondary.fontFamily || 'Arial',
      clipPath: new fabric.Rect({
        left: printArea.left,
        top: printArea.top,
        width: printArea.width,
        height: printArea.height,
        absolutePositioned: true
      }),
      selectable: true // Disable selection and interaction
    });
    secondaryTextRef.current.set({
      left: printArea.left + (printArea.width - secondaryTextRef.current.width) / 2,
    });
    secondaryTextRef.current.bringToFront();
    fabricCanvas.add(secondaryTextRef.current);
  }

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas || !productImage) return;

    const fabricCanvas = new fabric.Canvas(canvas, {
      width: canvas.clientWidth,
      height: canvas.clientHeight
    });

    fabricCanvasRef.current = fabricCanvas;

    // Load product image
    fabric.Image.fromURL(productImage, (img) => {
      const scaleFactor = Math.max(fabricCanvas.width / img.width, fabricCanvas.height / img.height);

      img.scale(scaleFactor);
      img.set({
        originX: 'center',
        originY: 'center',
        left: fabricCanvas.width / 2,
        top: fabricCanvas.height / 2
      });

      fabricCanvas.setBackgroundImage(img, fabricCanvas.renderAll.bind(fabricCanvas), {
        scaleX: scaleFactor,
        scaleY: scaleFactor,
        originX: 'center',
        originY: 'center'
      });


      // Define percentages for print area dimensions
      const printAreaWidthPercentage = parseFloat(printAreaData.printAreaWidthPercentage / 100);
      const printAreaHeightPercentage = parseFloat(printAreaData.printAreaHeightPercentage / 100);
      const offsetLeft = parseFloat(printAreaData.offsetLeft);
      const offsetTop = parseFloat(printAreaData.offsetTop);


      // Calculate print area dimensions based on canvas size and scaled image size
      const printAreaWidth = img.width * scaleFactor * printAreaWidthPercentage;
      const printAreaHeight = img.height * scaleFactor * printAreaHeightPercentage;
      const printAreaLeft = (fabricCanvas.width - img.width * scaleFactor) / 2 + (img.width * scaleFactor - printAreaWidth) / 2 + offsetLeft;
      const printAreaTop = (fabricCanvas.height - img.height * scaleFactor) / 2 + (img.height * scaleFactor - printAreaHeight) / 2 + offsetTop;

      const printArea = {
        left: printAreaLeft,
        top: printAreaTop,
        width: printAreaWidth,
        height: printAreaHeight
      };

      const printAreaRect = new fabric.Rect({
        left: printArea.left,
        top: printArea.top,
        width: printArea.width,
        height: printArea.height,
        fill: 'transparent',
        stroke: printAreaData.stroke,
        strokeWidth: 2,
        strokeDashArray: [5, 5],
        selectable: false
      });

      printAreaRectRef.current = printAreaRect;

      // Add the print area rectangle to the canvas
      fabricCanvas.add(printAreaRect);

      // Bring the print area rectangle to the front
      fabricCanvas.bringToFront(printAreaRect);

      // Ensure canvas updates after adding the rectangle
      fabricCanvas.renderAll();

      // Render design image
      renderDesignImage();

      // Render text
      renderPrimaryText();
      renderSecondaryText();
    }, { crossOrigin: 'anonymous' });

    // Add event listeners for object modifications and additions
    fabricCanvas.on('object:modified', () => {
      exportCanvasData(fabricCanvas.toJSON());
    });

    fabricCanvas.on('object:added', () => {
      exportCanvasData(fabricCanvas.toJSON());
    });


    // Cleanup function to dispose canvas when component unmounts
    return () => {
      fabricCanvas.dispose();
    };
    // eslint-disable-next-line
  }, [productImage, printAreaData]);

  useEffect(() => {
    renderDesignImage();
    // eslint-disable-next-line
  }, [designImage]);

  useEffect(() => {
    renderPrimaryText();
    // eslint-disable-next-line
  }, [text.primary]);

  useEffect(() => {
    renderSecondaryText();
    // eslint-disable-next-line
  }, [text.secondary]);

  return (
    <canvas ref={canvasRef} className="w-full h-full rounded-2xl" />
  );
}

export default DesignEditor;
