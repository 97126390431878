import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthenticate } from '../contexts/AuthenticateContext';

const useAdminAuthorization = () => {
    const { currentUser, authStatus } = useAuthenticate();
    const navigate = useNavigate();

    useEffect(() => {
        if (!authStatus.isLoading && (!currentUser || currentUser.userRole !== 'admin')) {
            navigate('/', { replace: true }); // Use replace: true to replace the current entry
        }
    }, [currentUser, authStatus, navigate]);

    return currentUser;
};

export default useAdminAuthorization;
