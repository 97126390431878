import React, { useState } from 'react'
import { useTopUpCredits } from '../contexts/TopUpCreditsContext';
import { useAuthenticate } from '../contexts/AuthenticateContext';
import { loadStripe } from '@stripe/stripe-js';

export default function TopUpCredits() {
    const { toggleTopUpCredits } = useTopUpCredits();
    const { currentUser } = useAuthenticate();
    const [creditAmount, setCreditAmpunt] = useState(50);

    const handleBuyCredits = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/payments/${currentUser.uid}/buyCredits`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ creditAmount }),
            });
            const data = await response.json();

            const sessionId = data.sessionId;

            const stripe = await loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);
            const { error } = await stripe.redirectToCheckout({
                sessionId: sessionId
            });

            if (error) {
                throw new Error(error.message);
            }
        } catch (error) {
            console.error('Error buying credits:', error);
        }
    };

    return (
        <div className='fixed inset-0 bg-black bg-opacity-70 z-50 flex items-center justify-center'>
            <div className='grid grid-cols-1 xl:grid-cols-2 bg-white w-[90%] sm:w-[70%] lg:w-[50%] min-h-3/4 rounded-2xl overflow-hidden'>
                <img src="https://image.tensorartassets.com/cdn-cgi/image/anim=true,w=2560,f=jpeg,q=85/posts/images/629260971684229814/df3674a2-8a04-4be2-b587-9ed4fc74d945.jpg" alt="" className='w-full h-full object-cover hidden xl:block' />
                <div className='relative overflow-hidden'>
                    <div className='w-6 h-6 rounded-full bg-black text-white font-bold flex items-center justify-center cursor-pointer absolute right-2 top-2 z-10' onClick={toggleTopUpCredits}>X</div>
                    <div className={`relative overflow-hidden p-4`}>
                        <div className='text-xs clear-start px-2 py-1 rounded-md bg-lime-100 font-medium w-fit'>{currentUser?.creditsAvailable} Credits Left</div>
                        <h2 className='mt-4 text-5xl font-extrabold text-stone-800 leading-10'>Top Up Your <span>Wallet</span></h2>
                        <p className='mt-4 text-gray-500 text-sm'>Add additional credits at a rate of $0.001 per credit</p>
                        <div className='mt-4 grid grid-cols-2 gap-4'>
                            <div className={`h-32 border-2 rounded-lg flex  flex-col items-center justify-center cursor-pointer hover:scale-105 transition-all ease-in-out duration-500 ${creditAmount === 50 ? 'border-lime-400' : ''}`} onClick={() => { setCreditAmpunt(50) }}>
                                <span className='font-medium text-sm'>50 Credits</span>
                            </div>
                            <div className={`h-32 border-2 rounded-lg flex  flex-col items-center justify-center cursor-pointer hover:scale-105 transition-all ease-in-out duration-500 ${creditAmount === 100 ? 'border-lime-400' : ''}`} onClick={() => { setCreditAmpunt(100) }}>
                                <span className='font-medium text-sm'>100 Credits</span>
                            </div>
                            <div className={`h-32 border-2 rounded-lg flex  flex-col items-center justify-center cursor-pointer hover:scale-105 transition-all ease-in-out duration-500 ${creditAmount === 150 ? 'border-lime-400' : ''}`} onClick={() => { setCreditAmpunt(150) }}>
                                <span className='font-medium text-sm'>150 Credits</span>
                            </div>
                            <div className={`h-32 border-2 rounded-lg flex  flex-col items-center justify-center cursor-pointer hover:scale-105 transition-all ease-in-out duration-500 ${creditAmount === 200 ? 'border-lime-400' : ''}`} onClick={() => { setCreditAmpunt(200) }}>
                                <span className='font-medium text-sm'>200 Credits</span>
                            </div>
                        </div>
                        <p className='mt-4 text-gray-500 text-end text-sm'>$0.10/credits</p>
                        <button className='mt-6 w-full py-3 bg-lime-400 rounded-3xl font-semibold' onClick={handleBuyCredits}>Buy Now</button>
                    </div>
                </div>
            </div>
        </div>
    )
}