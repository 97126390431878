import React, { useEffect, useRef } from 'react';
import { useAuthenticate } from '../contexts/AuthenticateContext';
import { useRestyledImages } from '../contexts/RestyledImagesContext';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';

const ToastComponent = () => {
    const navigate = useNavigate();
    const { currentUser, refreshUser } = useAuthenticate();
    const { restyledImages } = useRestyledImages();

    // Ref to keep track of the previous completionStatus
    const prevCompletionStatusRef = useRef({});
    // Ref to keep track of the toast IDs for each task
    const toastIdsRef = useRef({});

    useEffect(() => {
        const handleView = (restyledImageKey, successToastId) => {
            toast.dismiss(successToastId);
            navigate('/ai-image-restyle', { state: { restyledImageKey } });
            window.scrollTo(0, 0); // Scroll to the top of the page
        };

        if (restyledImages && currentUser && currentUser.uid) {
            Object.entries(restyledImages).forEach(([restyledImageKey, image]) => {
                const prevCompletionStatus = prevCompletionStatusRef.current[restyledImageKey];
                const existingToastId = toastIdsRef.current[restyledImageKey];

                if (image.completionStatus === 'success' && prevCompletionStatus === 'pending') {
                    if (existingToastId) {
                        toast.dismiss(existingToastId);
                    }
                    const successToastId = toast.success(<>
                        Holla! Magic spell successful.
                        <div className='text-lime-400 text-xs underline cursor-pointer' onClick={() => handleView(restyledImageKey, successToastId)}>Click here to view</div>
                    </>, {
                        autoClose: false,
                        theme: 'dark',
                        newestOnTop: true,
                    });
                    toastIdsRef.current[restyledImageKey] = successToastId;
                } else if (image.completionStatus === 'pending' && prevCompletionStatus !== 'pending') {
                    const progressToastId = toast.loading(`We're applying magic.`, {
                        autoClose: false,
                        theme: 'dark',
                        newestOnTop: true,
                        isLoading: true
                    });
                    toastIdsRef.current[restyledImageKey] = progressToastId;
                } else if (image.completionStatus === 'expired' && prevCompletionStatus === 'pending') {
                    if (existingToastId) {
                        toast.dismiss(existingToastId);
                    }
                    toast.error(`Oops! Magic spell didn't work.`, {
                        autoClose: false,
                        theme: 'dark',
                        newestOnTop: true,
                    });
                }

                prevCompletionStatusRef.current[restyledImageKey] = image.completionStatus;
            });
        }

        // Call refreshUser
        refreshUser();
        // eslint-disable-next-line
    }, [restyledImages]);

    return <ToastContainer position="bottom-right" />;
};

export default ToastComponent;
