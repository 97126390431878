import React, { useState, useEffect, useRef } from 'react';
import Datepicker from "react-tailwindcss-datepicker";
import JSZip from 'jszip';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { fabric } from 'fabric';
import { useAdminDataContext } from '../contexts/AdminDataContext';
import { useProducts } from '../contexts/ProductsContext';
const bwipjs = require('bwip-js');
const { PDFDocument, StandardFonts, rgb } = require('pdf-lib');

const OrderTimeline = ({ orderHistory }) => {
  const [steps, setSteps] = useState([]);

  useEffect(() => {
    const defaultSteps = [
      { id: 1, title: 'New Order', status: 'incomplete' },
      { id: 2, title: 'Print Files Downloaded', status: 'incomplete' },
      { id: 3, title: 'Waybill Generated', status: 'incomplete' }
    ];

    const updatedSteps = defaultSteps.map((step, index) => {
      const orderStep = orderHistory.find(history => history.status === step.title);
      if (orderStep) {
        return { ...step, status: 'completed' };
      } else if (index === orderHistory.length) {
        return { ...step, status: 'active' };
      }
      return step;
    });

    setSteps(updatedSteps);
  }, [orderHistory]);

  return (
    <div className="mt-8">
      <p className="text-sm font-medium">Track Order</p>
      <div className="mt-4 grid grid-cols-5 items-center">
        {steps.map((step, index) => (
          <React.Fragment key={step.id}>
            <div className={`col-span-1 ${index % 2 !== 0 ? 'col-start-odd' : ''}`}>
              <div className={`h-12 p-2 flex items-center justify-center rounded-lg cursor-pointer ${step.status === 'completed' ? 'border border-lime-400 shadow-md' : 'bg-gray-100'}`} role="button" aria-pressed={step.status === 'completed'}>
                <span className="text-[10px] font-medium text-center">{step.title}</span>
              </div>
            </div>
            {index < steps.length - 1 && (
              <div className="col-span-1 flex justify-center items-center">
                <div className={`h-px grow ${step.status === 'completed' ? 'bg-lime-400' : 'bg-gray-300'}`}></div>
                <div className={`w-6 h-6 shrink-0 rounded-full flex items-center justify-center text-xs ${steps[index + 1].status === 'completed' ? 'bg-lime-400' : 'bg-gray-300'}`}>
                  {step.id}
                </div>
                <div className={`h-px grow ${steps[index + 1].status === 'completed' ? 'bg-lime-400' : 'bg-gray-300'}`}></div>
              </div>
            )}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default function ManageOrders() {
  const { products } = useProducts();
  const { artifyOrders, isLoadingOrders, selectedDateRange, setSelectedDateRange, refreshData, searchOrder, orderStatus, setOrderStatus } = useAdminDataContext();

  const [activeOrder, setActiveOrder] = useState(null);
  const [selectedOrders, setSelectedOrders] = useState([]);

  // Filter orders based on selected status
  const filteredOrders = artifyOrders.filter(order => order.orderHistory[order.orderHistory.length - 1].status === orderStatus);
  const updateDateRange = newValue => setSelectedDateRange(newValue);

  // Function to toggle selection of all artifyOrders
  const toggleSelectAll = (e) => {
    const checked = e.target.checked;
    if (checked) {
      const orderIds = filteredOrders.map(order => order.orderId);
      setSelectedOrders(orderIds);
    } else {
      setSelectedOrders([]);
    }
  };

  // Function to toggle selection of individual order
  const toggleOrderSelection = (orderId) => {
    if (selectedOrders.includes(orderId)) {
      setSelectedOrders(selectedOrders.filter(id => id !== orderId));
    } else {
      setSelectedOrders([...selectedOrders, orderId]);
    }
  };

  const [isDownloadingFiles, setDownloadingFiles] = useState(false);
  async function downloadPrintFiles(selectedOrders) {
    setDownloadingFiles(true);
    const zip = new JSZip();
    const pdfPages = [];
    const pdfDoc = await PDFDocument.create();
    // Create a new workbook
    const wb = XLSX.utils.book_new();
    const HelveticaBold = await pdfDoc.embedFont(StandardFonts.HelveticaBold);
    const batchFolder = zip.folder(`Orders_Batch.${selectedDateRange.startDate}_${selectedDateRange.endDate}`);
    const printFilesFolder = batchFolder.folder('Print Files');
    const pngConversionPromises = [];

    // Define column headers with comments in English
    const excelExportColumns = [
      '*订单号', // Order Number
      '*店铺账号', // Shop Account
      '*sku', // SKU
      '属性(可填写SKU尺寸、颜色等)', // Attributes (e.g., Size: M, Color: Red)
      '*数量（大于0的整数）', // Quantity (Integer > 0)
      '*单价', // Unit Price
      '*总运费', // Total Shipping Cost
      '币种（默认USD）', // Currency (Default: USD)
      '买家指定物流', // Buyer Specified Shipping
      '发货仓库', // Warehouse
      '*买家姓名', // Buyer Name
      '*地址1', // Address Line 1
      '地址2', // Address Line 2
      '*城市', // City
      '*省/州', // State/Province
      '*国家二字码', // Country Code
      '*邮编', // Postal Code
      '电话', // Phone
      '手机', // Mobile
      'E-mail', // E-mail
      '买家税号', // Buyer Tax ID
      '门牌号', // Apartment/Unit Number
      '公司名', // Company Name
      '订单备注', // Order Note
      '图片网址', // Image URL
      '售出链接', // Sale URL
      '中文报关名', // Chinese Customs Name
      '英文报关名', // English Customs Name
      '申报金额（USD）', // Declared Value (USD)
      '申报重量（g）', // Declared Weight (g)
      '材质', // Material
      '用途', // Purpose
      '海关编码', // Customs Code
      '报关属性', // Customs Declaration Property
      '卖家税号（IOSS）', // Seller Tax ID (IOSS)
      '下单时间（北京时间）' // Order Date (Beijing Time)
    ];

    const excelExportData = [];

    for (const orderId of selectedOrders) {
      const order = filteredOrders.find(order => order.orderId === orderId);
      const product = products.find(product => order && order.orderedItems.some(item => product.variants.includes(item.variantId)));

      if (order && product) {
        try {
          // Check if the latest status in orderHistory is 'New Order'
          const latestStatus = order.orderHistory.length > 0
            ? order.orderHistory[order.orderHistory.length - 1].status
            : null;

          if (latestStatus === 'New Order') {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/orders/${orderId}/order-history`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({
                orderStatus: 'Print Files Downloaded',
                timestamp: new Date().toISOString()
              })
            });

            if (!response.ok) {
              throw new Error('Failed to update order history');
            }
          }
          const pagesContent = { 'orderBasicInfo': [], 'orderedItemsInfo': [] };

          pagesContent.orderBasicInfo.push({ text: 'Packing Slip', options: { size: 24, font: HelveticaBold, color: rgb(0, 0, 0) } });
          pagesContent.orderBasicInfo.push({ text: `Order Date: ${new Date(order.timestamp._seconds * 1000 + order.timestamp._nanoseconds / 1000000).toLocaleString('en-GB', { hour12: true }).replace(/\//g, '-')}`, options: { size: 8, margin: 10 } });
          pagesContent.orderBasicInfo.push({ text: `Order ID: ${orderId}`, options: { size: 8, margin: 10 } });
          pagesContent.orderBasicInfo.push({ text: 'Customer Info', options: { size: 10, font: HelveticaBold } });
          pagesContent.orderBasicInfo.push({ text: `Name: ${order.customerInfo.name}, Email: ${order.customerInfo.email}, Phone: ${order.customerInfo.phone}, Address: ${order.customerInfo.address.line1}, ${order.customerInfo.address.city}, ${order.customerInfo.address.state}, ${order.customerInfo.address.postal_code}, ${order.customerInfo.address.country}`, options: { size: 8, margin: 10 } });
          pagesContent.orderBasicInfo.push({ horizontalLine: 'horizontalLine', options: { thickness: 1, color: rgb(0.8, 0.8, 0.8), margin: 20 } });
          pagesContent.orderBasicInfo.push({ text: '', options: { size: 8 } });
          pagesContent.orderBasicInfo.push({ text: 'Ordered Items:', options: { size: 10, font: HelveticaBold, margin: 10 } });
          order.orderedItems.forEach((item, itemIndex) => {
            const orderInfo = [];
            orderInfo.push({ text: `${itemIndex + 1}. ${product.name} • Size: ${item.size} • Quantity: ${item.quantity}`, options: { size: 8 } });
            let textCount = 1;
            item?.canvasData?.objects.forEach(obj => {
              if (obj.type === 'text') {
                orderInfo.push({ text: `    Text ${textCount}: ${obj.text} • Text Color: ${obj.fill} • Font Size: ${obj.fontSize} • Font Family: ${obj.fontFamily}`, options: { size: 8 } });
                textCount++; // Increment the counter for the next text object
              }
            })
            orderInfo.push({ text: `    Amount Paid: $ ${item.unit_amount / 100} ${item.currency.toUpperCase()}`, options: { size: 8 } });
            orderInfo.push({ barcode: `${orderId}_Design_${item.variantId}.png`, options: { margin: 20 } });

            // Prepare data for Excel export inside the loop
            excelExportData.push([
              orderId, // *订单号 (Order ID)
              '手工订单', // *店铺账号 (Manual Order)
              item.variantId, // *sku (Variant ID)
              `${item.size}`, // 属性 (Attributes: Size, Color, etc.)
              item.quantity, // *数量（大于0的整数） (Quantity: Integer > 0)
              item.unit_amount / 100, // *单价 (Unit Price)
              12, // *总运费 (Total Shipping Cost)
              item.currency, // 币种（默认USD） (Currency: Default: USD)
              '自定义文本1', // 买家指定物流 (Buyer Specified Shipping)
              '默认仓库', // 发货仓库 (Warehouse)
              order.customerInfo.name, // *买家姓名 (Buyer Name)
              order.customerInfo.address.line1, // *地址1 (Address Line 1)
              order.customerInfo.address.line2, // 地址2 (Address Line 2)
              order.customerInfo.address.city, // *城市 (City)
              order.customerInfo.address.state, // *省/州 (State/Province)
              order.customerInfo.address.country || 'cn', // *国家二字码 (Country Code)
              order.customerInfo.address.postal_code, // *邮编 (Postal Code)
              order.customerInfo.phone, // 电话 (Phone)
              order.customerInfo.phone, // 手机 (Mobile)
              order.customerInfo.email, // E-mail
              order.customerInfo.buyer_tax_id || 'N/A', // 买家税号 (Buyer Tax ID)
              order.customerInfo.apartment_unit_number || 'N/A', // 门牌号 (Apartment/Unit Number)
              order.customerInfo.company_name || 'N/A', // 公司名 (Company Name)
              order.order_note || '', // 订单备注 (Order Note)
              '', // 图片网址 (Image URL)
              '', // 售出链接 (Sale URL)
              '', // 中文报关名 (Chinese Customs Name)
              '', // 英文报关名 (English Customs Name)
              '', // 申报金额（USD） (Declared Value (USD))
              '', // 申报重量（g） (Declared Weight (g))
              '', // 材质 (Material)
              '', // 用途 (Purpose)
              '', // 海关编码 (Customs Code)
              '', // 报关属性 (Customs Declaration Property)
              '', // 卖家税号（IOSS） (Seller Tax ID (IOSS))
              new Date(order.timestamp._seconds * 1000 + order.timestamp._nanoseconds / 1000000).toLocaleString('en-GB', { hour12: true }).replace(/\//g, '-'), // 下单时间（北京时间） (Order Date (Beijing Time))
            ]);

            pngConversionPromises.push(
              convertCanvasDataToPNG(item.canvasData)
                .then(([productImage, designImage]) => {
                  const variantId = item.variantId;
                  const designFileName = `${orderId}_Design_${variantId}.png`;
                  printFilesFolder.file(designFileName, designImage.split(';base64,')[1], { base64: true });
                  orderInfo.push({ image: productImage.split(';base64,')[1], options: { width: 300, height: 300, margin: 20 } });
                })
                .catch(error => {
                  console.error('Error converting canvasData to PNG:', error);
                })
            );
            pagesContent.orderedItemsInfo.push(orderInfo);
          });

          pdfPages.push(pagesContent);
          await Promise.all(pngConversionPromises);
        } catch (error) {
          console.error('Error updating order history:', error);
          continue; // Skip to the next order if there's an error during the HTTP request
        }
      }
    }
    await drawTextContent(pdfPages);

    // Saving Pdf
    const pdfBytes = await pdfDoc.save();
    batchFolder.file('packing-slip.pdf', pdfBytes);

    // Saving Data in Excel Sheet
    const ws = XLSX.utils.aoa_to_sheet([excelExportColumns, ...excelExportData]);
    // Add worksheet to workbook
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    // Generate Excel file
    const excelBytes = XLSX.write(wb, { type: 'buffer', bookType: 'xlsx' });
    batchFolder.file('orders-data.xlsx', excelBytes);

    await Promise.all(pngConversionPromises);
    zip.generateAsync({ type: 'blob' }).then(blob => {
      const currentDate = new Date();
      const dateString = currentDate.toISOString().slice(0, 10).replace(/-/g, '');
      const timeString = currentDate.toTimeString().split(' ')[0].replace(/:/g, '');
      const filename = `Orders_Batch_${selectedDateRange.startDate}_${selectedDateRange.endDate}_${dateString}_${timeString}.zip`;
      setDownloadingFiles(false);
      setSelectedOrders([])
      refreshData('orders');
      saveAs(blob, filename);
    }).catch(error => {
      console.error('Error generating zip file:', error);
    });

    async function drawTextContent(pdfPages) {
      for (const pagesContent of pdfPages) {
        const page = pdfDoc.addPage();
        const { width, height } = page.getSize();

        const startPosition = { x: 50, y: height - 50 };

        let currentY = startPosition.y;

        // Process orderBasicInfo
        for (const { text, horizontalLine, options } of pagesContent.orderBasicInfo) {
          if (text) {
            page.drawText(text, {
              x: startPosition.x,
              y: currentY,
              size: options.size,
              font: options.font,
              color: options.color,
            });
            currentY -= (options.size + (options.margin || 5));
          }
          if (horizontalLine) {
            page.drawLine({
              start: { x: startPosition.x, y: currentY },
              end: { x: startPosition.x + (options.width || width - 100), y: currentY },
              thickness: options.thickness || 1,
              color: options.color || rgb(0, 0, 0),
            });
            currentY -= (options.thickness + (options.margin || 5));
          }
        }

        // Process orderedItemsInfo
        for (const itemArray of pagesContent.orderedItemsInfo) {
          for (const { text, horizontalLine, barcode, image, options } of itemArray) {
            if (text) {
              page.drawText(text, {
                x: startPosition.x,
                y: currentY,
                size: options.size,
                font: options.font,
                color: options.color,
              });
              currentY -= (options.size + (options.margin || 5));
            }
            if (horizontalLine) {
              page.drawLine({
                start: { x: startPosition.x, y: currentY },
                end: { x: startPosition.x + (options.width || width - 100), y: currentY },
                thickness: options.thickness || 1,
                color: options.color || rgb(0, 0, 0),
              });
              currentY -= (options.thickness + (options.margin || 5));
            }
            if (barcode) {
              const canvas = document.createElement('canvas');
              try {
                bwipjs.toCanvas(canvas, {
                  bcid: 'code128',
                  text: barcode,
                  scale: 3,
                  includetext: false,
                  textxalign: 'center',
                });
                const barcodeBase64 = canvas.toDataURL('image/png').split(';base64,')[1];
                const barcodeImageBytes = base64ToArrayBuffer(barcodeBase64);
                const barcodeImage = await pdfDoc.embedPng(barcodeImageBytes);
                const barcodeWidth = 200;
                const barcodeHeight = 20;
                await page.drawImage(barcodeImage, {
                  x: startPosition.x + 10,
                  y: currentY - barcodeHeight,
                  width: barcodeWidth,
                  height: barcodeHeight,
                });
                currentY -= (barcodeHeight + (options.margin || 5));
              } catch (error) {
                console.error('Error generating or embedding barcode:', error);
              }
            }
            if (image) {
              try {
                const imageData = base64ToArrayBuffer(image);
                const embeddedImage = await pdfDoc.embedPng(imageData);
                await page.drawImage(embeddedImage, {
                  x: startPosition.x,
                  y: currentY - options.height, // Adjust as needed
                  width: options.width,
                  height: options.height,
                });
                currentY -= (150 + (options.margin || 5));
              } catch (error) {
                console.error('Error embedding image:', error);
              }
            }
          }
        }
      }
    }

    function base64ToArrayBuffer(base64) {
      const binaryString = window.atob(base64);
      const bytes = new Uint8Array(binaryString.length);
      for (let i = 0; i < binaryString.length; i++) {
        bytes[i] = binaryString.charCodeAt(i);
      }
      return bytes.buffer;
    }
  }

  function convertCanvasDataToPNG(jsonData) {
    return new Promise((resolve, reject) => {
      const canvas = new fabric.Canvas();
      canvas.loadFromJSON(jsonData, () => {
        // Get the background image
        const backgroundImage = canvas.backgroundImage;

        // Get the dimensions of the background image
        const bgWidth = backgroundImage.width;
        const bgHeight = backgroundImage.height;

        // Set canvas dimensions to match the background image
        canvas.setDimensions({ width: bgWidth, height: bgHeight, originX: 'left', originY: 'top' });

        // Convert canvas to PNG with background
        const productImage = canvas.toDataURL({
          format: 'png',
          multiplier: 4, // No scaling
          quality: 1, // Maximum quality
        });

        // Get all rectangles on the canvas
        const rectangles = canvas.getObjects().filter(obj => obj.type === 'rect');

        if (rectangles.length === 0) {
          reject(new Error('No rectangles found on the canvas.'));
          return;
        }

        // Find the bounding box of all rectangles
        let minX = Infinity, minY = Infinity, maxX = -Infinity, maxY = -Infinity;
        rectangles.forEach(rect => {
          const { left, top, width, height } = rect;
          minX = Math.min(minX, left);
          minY = Math.min(minY, top);
          maxX = Math.max(maxX, left + width);
          maxY = Math.max(maxY, top + height);
        });

        // Calculate the width and height based on the bounding box
        const width = maxX - minX;
        const height = maxY - minY;

        // Remove rectangles from canvas objects
        canvas.getObjects().forEach(obj => {
          if (obj.type === 'rect') {
            canvas.remove(obj);
          }
        });

        // Set canvas dimensions to match the bounding box
        canvas.setDimensions({ width, height, originX: 'left', originY: 'top' });
        canvas.backgroundImage = null;
        // Convert canvas to PNG without background and rectangles
        const designImage = canvas.toDataURL({
          format: 'png',
          multiplier: 4, // No scaling
          quality: 1, // Maximum quality
          left: minX,
          top: minY,
          width,
          height
        });

        resolve([productImage, designImage]);
      });
    });
  }

  const handleDownloadPrintFiles = (selectedOrders) => {
    if (!selectedOrders || selectedOrders.length < 1) return;
    downloadPrintFiles(selectedOrders);
  };

  // Processing Orders For Waybill Number
  const [importingData, isImportingData] = useState(false);
  async function selectAndProcessExcel() {
    try {
      const file = await getFile('.xlsx,.xls');
      if (!file) throw new Error('No file selected');

      isImportingData(true);

      const workbook = await readWorkbook(file);
      if (!workbook) throw new Error('Error reading workbook');

      const orderWaybills = getOrderWaybills(workbook);
      if (orderWaybills.length === 0) throw new Error('No valid waybill numbers found');

      await updateOrderHistory(orderWaybills);
      refreshData('orders');
    } catch (error) {
      console.error(`Error: ${error.message}`);
    } finally {
      isImportingData(false);
    }
  }

  function getFile(accept) {
    return new Promise((resolve) => {
      const input = document.createElement('input');
      input.type = 'file';
      input.accept = accept;
      input.onchange = (event) => resolve(event.target.files[0]);
      input.click();
    });
  }

  function readWorkbook(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        const data = new Uint8Array(reader.result);
        try {
          resolve(XLSX.read(data, { type: 'array' }));
        } catch (error) {
          reject(new Error('Error reading workbook'));
        }
      };
      reader.readAsArrayBuffer(file);
    });
  }

  function getOrderWaybills(workbook) {
    const orderWaybills = [];
    const headerNames = ['订单号', '运单号'];
    workbook.SheetNames.forEach(sheetName => {
      const worksheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
      const headerRow = jsonData[0];
      const indices = headerNames.map(name => headerRow.indexOf(name));
      if (indices.every(index => index !== -1)) {
        jsonData.slice(1).forEach(row => {
          const [orderIndex, waybillIndex] = indices;
          const [orderId, wayBillNumber] = [row[orderIndex], row[waybillIndex]];
          if (selectedOrders.includes(orderId) && wayBillNumber) {
            orderWaybills.push({ orderId, wayBillNumber });
          }
        });
      }
    });
    return orderWaybills;
  }

  async function updateOrderHistory(orderWaybills) {
    const promises = orderWaybills.map(async ({ orderId, wayBillNumber }) => {
      const endpoint = `${process.env.REACT_APP_BACKEND_URL}/orders/${orderId}/order-history`;
      const currentOrder = filteredOrders.find(order => order.orderId === orderId);
      const latestStatus = currentOrder.orderHistory.length > 0 ? currentOrder.orderHistory[currentOrder.orderHistory.length - 1].status : null;
      if (latestStatus !== 'Waybill Generated') {
        try {
          await fetch(endpoint, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ orderId, orderStatus: 'Waybill Generated', timestamp: new Date().toISOString(), wayBillNumber })
          });
        } catch (error) {
          console.error(`Error updating order history for orderId: ${orderId}`);
        }
      }
    });
    await Promise.all(promises);
  }

  const [variantNames, setVariantNames] = useState({});

  useEffect(() => {
    const fetchVariantData = async (variantId) => {
      if (!variantId) return 'Variant Not Found';

      const url = `${process.env.REACT_APP_BACKEND_URL}/products/variants/${variantId}`;

      try {
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error(`Error fetching variant: ${response.statusText}`);
        }
        const data = await response.json();
        return data.variant.name + ' / ' + data.variant.color || 'Variant Not Found';
      } catch (err) {
        console.error(err.message);
        return 'Variant Not Found';
      }
    };

    const fetchVariantNames = async () => {
      try {
        const names = await Promise.all(
          activeOrder.orderedItems.map(async (item) => {
            const name = await fetchVariantData(item.variantId);
            return { [item.variantId]: name };
          })
        );

        // Merge new names into existing state using spread operator
        setVariantNames((prevNames) => {
          const newNames = names.reduce((acc, nameObj) => {
            return { ...acc, ...nameObj };
          }, {});
          return { ...prevNames, ...newNames };
        });
      } catch (error) {
        console.error('Error fetching variant names:', error.message);
        // Handle error state if needed
      }
    };

    if (activeOrder && activeOrder.orderedItems) {
      fetchVariantNames();
    } else {
      setVariantNames({});
    }
  }, [activeOrder]); // Re-run effect when activeOrder changes

  const searchOrderRef = useRef(null);

  return (
    <div className={`${isLoadingOrders ? 'animate-pulse' : ''}`}>
      <div className="w-full min-h-[400px] relative flex flex-col bg-clip-border rounded-xl bg-white text-gray-700 shadow-md xl:col-span-2">
        <div className="relative bg-clip-border rounded-xl bg-transparent text-gray-700 shadow-none m-0 flex flex-wrap items-center justify-between gap-2 px-6 py-4">
          <div className='shrink-0'>
            <h6 className="block antialiased tracking-normal font-sans text-base font-semibold leading-relaxed text-blue-gray-900 mb-1">Orders</h6>
            <p className="antialiased font-sans text-sm leading-normal flex items-center gap-1 font-normal">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="3" stroke="currentColor" aria-hidden="true" className="h-4 w-4 text-blue-500">
                <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5"></path>
              </svg>
              <strong>{filteredOrders.length + ' in ' + orderStatus}</strong>
            </p>
          </div>
          {/* Add your button here if needed */}
          <div className='relative flex items-center flex-wrap gap-4'>
            <div className='border rounded-lg inline-flex items-center justify-between gap-2 px-4 py-2'>
              <input ref={searchOrderRef} type="text" placeholder='Order ID' className='text-xs outline-none border-none font-semibold' />
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6 cursor-pointer" onClick={() => { searchOrder(searchOrderRef) }}>
                <path strokeLinecap="round" strokeLinejoin="round" d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z" />
              </svg>
            </div>
            {(orderStatus === 'New Order' || orderStatus === 'Print Files Downloaded' || orderStatus === 'Waybill Generated') && (
              <button className={`shrink-0 text-xs px-4 py-3 border rounded-lg font-semibold inline-flex items-center justify-between gap-2 ${selectedOrders.length > 0 ? 'border-lime-400 bg-lime-400 cursor-pointer text-gray-800' : 'border-lime-300 bg-lime-300 cursor-not-allowed text-gray-600'}`} onClick={() => { handleDownloadPrintFiles(selectedOrders) }}>
                Download Print Files
                {isDownloadingFiles && (
                  <div role="status">
                    <svg aria-hidden="true" className="inline w-4 h-4 text-lime-400 animate-spin fill-black" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                      <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                    </svg>
                    <span className="sr-only">Loading...</span>
                  </div>
                )}
              </button>
            )}
            {orderStatus === 'Print Files Downloaded' && (
              <button className={`shrink-0 text-xs px-4 py-3 border rounded-lg font-semibold inline-flex items-center justify-between gap-2 ${selectedOrders.length > 0 ? 'border-lime-400 bg-lime-400 cursor-pointer text-gray-800' : 'border-lime-300 bg-lime-300 cursor-not-allowed text-gray-600'}`} onClick={selectAndProcessExcel}>
                Import Data
                {importingData && (
                  <div role="status">
                    <svg aria-hidden="true" className="inline w-4 h-4 text-lime-400 animate-spin fill-black" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                      <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                    </svg>
                    <span className="sr-only">Loading...</span>
                  </div>
                )}
              </button>
            )}
            <div>
            <Datepicker inputClassName="text-xs px-4 py-3 border border-lime-400 rounded-lg w-60" placeholder='Date Range' displayFormat={"DD-MM-YYYY"} separator={"to"} value={selectedDateRange} onChange={updateDateRange} />
            </div>
          </div>
        </div>
        <div className='px-6 flex items-center gap-2'>
          <span className={`px-3 py-1 text-[10px] border border-black font-medium rounded cursor-pointer ${orderStatus === 'New Order' ? 'text-black' : 'bg-black text-white'}`} onClick={() => { setSelectedOrders([]); setOrderStatus('New Order') }}>New Order</span>
          <span className={`px-3 py-1 text-[10px] border border-black font-medium rounded cursor-pointer ${orderStatus === 'Print Files Downloaded' ? 'text-black' : 'bg-black text-white'}`} onClick={() => { setSelectedOrders([]); setOrderStatus('Print Files Downloaded') }}>Print Files Downloaded</span>
          <span className={`px-3 py-1 text-[10px] border border-black font-medium rounded cursor-pointer ${orderStatus === 'Waybill Generated' ? 'text-black' : 'bg-black text-white'}`} onClick={() => { setSelectedOrders([]); setOrderStatus('Waybill Generated') }}>Waybill Generated</span>
        </div>
        <div className="overflow-x-scroll px-0 pt-0 hide-scrollbar">
          <table className="w-full min-w-[640px] table-auto">
            <thead>
              <tr>
                <th className='border-b border-blue-gray-50 py-3 pl-2'>
                  <input type="checkbox" onChange={toggleSelectAll} checked={selectedOrders?.length > 0} />
                </th>
                <th className="border-b border-blue-gray-50 py-3 px-6 text-left">
                  <p className="block antialiased font-sans text-[11px] font-medium uppercase text-blue-gray-400 truncate">Order ID</p>
                </th>
                <th className="border-b border-blue-gray-50 py-3 px-6 text-left">
                  <p className="block antialiased font-sans text-[11px] font-medium uppercase text-blue-gray-400 truncate">Order Date</p>
                </th>
                <th className="border-b border-blue-gray-50 py-3 px-6 text-left">
                  <p className="block antialiased font-sans text-[11px] font-medium uppercase text-blue-gray-400">Customer</p>
                </th>
                <th className="border-b border-blue-gray-50 py-3 px-6 text-left">
                  <p className="block antialiased font-sans text-[11px] font-medium uppercase text-blue-gray-400">Email</p>
                </th>
                <th className="border-b border-blue-gray-50 py-3 px-6 text-left">
                  <p className="block antialiased font-sans text-[11px] font-medium uppercase text-blue-gray-400">Phone</p>
                </th>
                <th className="border-b border-blue-gray-50 py-3 px-6 text-left">
                  <p className="block antialiased font-sans text-[11px] font-medium uppercase text-blue-gray-400 truncate">Shipping Address</p>
                </th>
                <th className="border-b border-blue-gray-50 py-3 px-6 text-left">
                  <p className="block antialiased font-sans text-[11px] font-medium uppercase text-blue-gray-400">Action</p>
                </th>
                <th className="border-b border-blue-gray-50 py-3 px-6 text-left"></th>
              </tr>
            </thead>
            <tbody>
              {filteredOrders.map((order, index) => (
                <tr key={index} className='border-b border-blue-gray-50'>
                  <td className="py-3 pl-2">
                    <input type="checkbox" onChange={() => toggleOrderSelection(order.orderId)} checked={selectedOrders?.includes(order.orderId)} />
                  </td>
                  <td className="py-3 px-5">
                    <p className="block antialiased font-sans text-xs font-medium w-44 truncate">{order.orderId}</p>
                  </td>
                  <td className="py-3 px-5">
                    <p className="block antialiased font-sans text-xs font-medium uppercase truncate">{new Date(order.timestamp._seconds * 1000 + order.timestamp._nanoseconds / 1000000).toLocaleString('en-GB', { hour12: true }).replace(/\//g, '-')}</p>
                  </td>
                  <td className="py-3 px-5">
                    <p className="block antialiased font-sans text-xs font-medium">{order.customerInfo.name}</p>
                  </td>
                  <td className="py-3 px-5">
                    <p className="block antialiased font-sans text-xs font-medium">{order.customerInfo.email}</p>
                  </td>
                  <td className="py-3 px-5">
                    <p className="block antialiased font-sans text-xs font-medium">{order.customerInfo.phone}</p>
                  </td>
                  <td className="py-3 px-5">
                    <p className="block antialiased font-sans text-xs font-medium w-44 truncate">
                      {order.customerInfo.address.line1}, {order.customerInfo.address.line2}, {order.customerInfo.address.city}, {order.customerInfo.address.state}, {order.customerInfo.address.postal_code}, {order.customerInfo.address.country}
                    </p>
                  </td>
                  <td className="py-3 px-5">
                    <p className="block antialiased font-sans text-[11px] font-semibold cursor-pointer px-2 py-1 rounded-md bg-lime-200 w-fit" onClick={() => { setActiveOrder(order) }}>Manage</p>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      {activeOrder && (
        <div className='fixed inset-0 z-50 bg-black bg-opacity-70 flex items-center justify-center'>
          <div className='mx-auto w-1/2 h-3/4 rounded-3xl bg-white overflow-y-auto p-6'>
            <div className='flex items-center justify-between'>
              <span className='text-sm font-medium'>Manage Order</span>
              <span className='w-8 h-8 inline-flex items-center justify-center rounded-full bg-black text-white cursor-pointer' onClick={() => { setActiveOrder(null) }}>X</span>
            </div>
            <hr className='mt-4' />
            {/* Customer Details */}
            <div className='flex flex-col gap-2 mt-4'>
              <p className='text-sm font-medium'>Customer Details</p>
              <p className='text-xs'>Name: {activeOrder.customerInfo.name}</p>
              <p className='text-xs'>Email: {activeOrder.customerInfo.email}</p>
              <p className='text-xs'>Phone: {activeOrder.customerInfo.phone}</p>
              <p className='text-xs'>Shipping To: {activeOrder.customerInfo.address.line1}, {activeOrder.customerInfo.address.line2}, {activeOrder.customerInfo.address.city}, {activeOrder.customerInfo.address.state}, {activeOrder.customerInfo.address.postal_code}, {activeOrder.customerInfo.address.country}</p>
            </div>
            <hr className='mt-4' />
            {/* Ordered Items Details */}
            <div className='flex flex-col gap-2 mt-4'>
              <p className='text-sm font-medium'>Order Details</p>
              <p className='text-[9px]'>Time: {new Date(activeOrder.timestamp._seconds * 1000 + activeOrder.timestamp._nanoseconds / 1000000).toString()}</p>
              <p className='text-[9px]'>Order ID: {activeOrder.orderId}</p>
              <table className="w-full table-auto">
                <thead>
                  <tr>
                    <th className="border-b border-blue-gray-50 py-3 text-left">
                      <p className="block antialiased font-sans text-[11px] font-medium uppercase text-blue-gray-400">Product Name / Color</p>
                    </th>
                    <th className="border-b border-blue-gray-50 py-3 px-6 text-left">
                      <p className="block antialiased font-sans text-[11px] font-medium uppercase text-blue-gray-400">Size</p>
                    </th>
                    <th className="border-b border-blue-gray-50 py-3 px-6 text-left">
                      <p className="block antialiased font-sans text-[11px] font-medium uppercase text-blue-gray-400">Quantity</p>
                    </th>
                    <th className="border-b border-blue-gray-50 py-3 px-6 text-left">
                      <p className="block antialiased font-sans text-[11px] font-medium uppercase text-blue-gray-400">Amount</p>
                    </th>
                    {orderStatus === 'New Order' && (
                      <th className="border-b border-blue-gray-50 py-3 px-6 text-left">
                        <p className="block antialiased font-sans text-[11px] font-medium uppercase text-blue-gray-400">Print Files</p>
                      </th>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {activeOrder.orderedItems.map((item, index) => (
                    <tr key={index} className='border-b border-blue-gray-50'>
                      <td className="py-3">
                        <p className="block antialiased font-sans text-xs font-medium w-52 truncate">{variantNames[item.variantId] || 'Loading...'}</p>
                      </td>
                      <td className="py-3 px-5">
                        <p className="block antialiased font-sans text-xs font-medium">{item.size}</p>
                      </td>
                      <td className="py-3 px-5">
                        <p className="block antialiased font-sans text-xs font-medium">{item.quantity}</p>
                      </td>
                      <td className="py-3 px-5">
                        <p className="block antialiased font-sans text-xs font-medium uppercase">{item.currency + ' ' + (item.unit_amount / 100).toFixed(2)}</p>
                      </td>
                      {orderStatus === 'New Order' && (
                        <td className="py-3 px-5">
                          <p className="block antialiased font-sans text-[11px] font-medium cursor-pointer px-2 py-1 rounded-md bg-lime-200 w-fit" onClick={() => { handleDownloadPrintFiles([activeOrder.orderId]) }}>Download</p>
                        </td>
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <OrderTimeline orderHistory={activeOrder.orderHistory} />

          </div>
        </div>
      )}
    </div>
  );
}