import React, { createContext, useState, useContext } from 'react';

// Create a context for managing Top Up Credits visibility and toggle function
const TopUpCreditsContext = createContext();

// Custom hook to access the context values
export const useTopUpCredits = () => useContext(TopUpCreditsContext);

// Provider component to wrap around the components that need access to these values
export const TopUpCreditsProvider = ({ children }) => {
  // State to manage the visibility of the Top Up Credits component, initially hidden
  const [isTopUpCreditsVisible, setIsTopUpCreditsVisible] = useState(false);

  // Function to toggle the visibility of the Top Up Credits component
  const toggleTopUpCredits = () => {
    setIsTopUpCreditsVisible(prevState => !prevState);
  };

  return (
    <TopUpCreditsContext.Provider value={{ isTopUpCreditsVisible, toggleTopUpCredits }}>
      {children}
    </TopUpCreditsContext.Provider>
  );
};
