import React from 'react'
import ManageOrders from './ManageOrders'
import ManageUsers from './ManageUsers'
import Statistics from './Statistics'

export default function Home() {
  return (
    <>
      <div className='mb-12 '>
        <Statistics />
      </div>
      <div className='w-full h-full grid grid-cols-1 xl:grid-cols-2 gap-4'>
        <div className='w-full h-full '>
          <ManageUsers />
        </div>
        <div className='w-full h-full '>
          <ManageOrders />
        </div>
      </div>
    </>

  )
}