// RestyledImagesContext.js
import React, { createContext, useContext, useEffect, useState } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import { get, ref, onValue } from 'firebase/database';
import { auth, realtimeDatabase } from '../firebase/firebaseFunctions';

const RestyledImagesContext = createContext();

export const useRestyledImages = () => {
    return useContext(RestyledImagesContext);
};

export const RestyledImagesProvider = ({ children }) => {
    const [gettingRestyledImages, isGettingRestyledImages] = useState(true);
    const [restyledImages, setRestyledImages] = useState(null);
    const [error, setError] = useState();

    useEffect(() => {
        const unsubscribeAuth = onAuthStateChanged(auth, async (user) => {
            try {
                if (user) {
                    const userRef = ref(realtimeDatabase, `users/${user.uid}`);
                    const snapshot = await get(userRef);
                    const userData = snapshot.val();

                    // Set restyledImages to null if userData is not available
                    setRestyledImages(userData || null);

                    // Set up a real-time listener for changes in user images
                    const unsubscribeData = onValue(userRef, (snapshot) => {
                        const updatedUserData = snapshot.val();
                        setRestyledImages(updatedUserData || null);
                    });

                    isGettingRestyledImages(false);
                    return () => {
                        // Cleanup: remove the real-time listener when the component unmounts
                        unsubscribeData();
                    };
                } else {
                    setRestyledImages(null);
                    isGettingRestyledImages(false);
                }
            } catch (error) {
                setError(`Error fetching user images: ${error.message}`);
                console.error(error);
                isGettingRestyledImages(false);
            }
        });

        // Cleanup: unsubscribe from the auth state listener when the component unmounts
        return () => {
            unsubscribeAuth();
        };
    }, []);

    return (
        <RestyledImagesContext.Provider value={{ restyledImages, error, gettingRestyledImages }}>
            {children}
        </RestyledImagesContext.Provider>
    );
};
