import React, { useEffect, useRef, useState } from 'react';
import Lottie from 'lottie-react';
import trackOrderLottie from '../assets/trackOrderLottie.json';
import truckLottie from '../assets/truckLottie.json';
import { useParams } from 'react-router-dom';

export default function TrackOrder() {
    let { trackingId: paramTrackingId } = useParams();
    const [trackingId, setTrackingId] = useState(paramTrackingId || '');
    const inputRef = useRef(null);
    const containerRef = useRef(null);

    const doTrack = () => {
        const num = inputRef.current.value;
        if (num === "") {
            alert("Enter your number.");
            return;
        }
        window.YQV5.trackSingle({
            // Required, Specify the container ID of the carrier content.
            YQ_ContainerId: "YQContainer",
            // Optional, specify tracking result height, max height 800px, default is 560px.
            YQ_Height: 560,
            // Optional, select carrier, default to auto identify.
            YQ_Fc: "0",
            // Optional, specify UI language, default language is automatically detected based on the browser settings.
            YQ_Lang: "EN",
            // Required, specify the number needed to be tracked.
            YQ_Num: num
        });
    };

    useEffect(() => {
        const script = document.createElement('script');
        script.src = "//www.17track.net/externalcall.js";
        script.async = true;
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    return (
        <div className='w-full container mx-auto px-6 xl:px-0 grid grid-cols-1 xl:grid-cols-2 items-center'>
            <div className='order-2 xl:order-1'>
                <Lottie animationData={truckLottie} />
            </div>
            <div className='order-1 xl:order-2 flex flex-col gap-3'>
                <h2 className='text-xl xl:text-3xl font-bold'>Track Your Order</h2>
                <p className='text-gray-500 text-xs'>Enter your order number to track your package</p>
                <input 
                    type="text" 
                    id="YQNum" 
                    maxLength="50" 
                    ref={inputRef} 
                    value={trackingId} 
                    onChange={(e) => setTrackingId(e.target.value)} 
                    className='xl:w-[350px] border rounded px-2 py-3 text-sm' 
                    placeholder='Enter Order Id' 
                />
                <input 
                    type="button" 
                    value="TRACK" 
                    className='xl:w-[350px] bg-black text-white font-normal rounded px-2 py-3 text-sm inline-flex items-center justify-center gap-4' 
                    onClick={doTrack} 
                />
                <div className='xl:w-[350px]'>
                    <Lottie animationData={trackOrderLottie} />
                </div>
                <div id="YQContainer" className='w-full' ref={containerRef}></div>
            </div>
        </div>
    );
}
