import React, { createContext, useState, useEffect, useContext, useCallback } from 'react';

const AdminDataContext = createContext();

export const useAdminDataContext = () => {
  return useContext(AdminDataContext);
};

export const AdminDataProvider = ({ children }) => {
  const [artifyUsers, setArtifyUsers] = useState([]);
  const [isLoadingUsers, setIsLoadingUsers] = useState(true);
  const [artifyOrders, setArtifyOrders] = useState([]);
  const [orderStatus, setOrderStatus] = useState('New Order');
  const [isLoadingOrders, setIsLoadingOrders] = useState(true);

  const [selectedDateRange, setSelectedDateRange] = useState({
    startDate: (() => {
      const startDate = new Date();
      startDate.setDate(startDate.getDate() - 7); // Set start date to 7 days before today
      return startDate;
    })(),
    endDate: new Date() // Set end date to today's date
  });

  const fetchUsers = useCallback(() => {
    setIsLoadingUsers(true);
    fetch(`${process.env.REACT_APP_BACKEND_URL}/user/listall`)
      .then(response => {
        if (!response.ok) {
          throw new Error('Failed to fetch users');
        }
        return response.json();
      })
      .then(data => {
        if (data.success) {
          setArtifyUsers(data.users);
        } else {
          console.error('Error: Request to fetch users was not successful.');
        }
      })
      .catch(error => {
        console.error('Error fetching users:', error);
      })
      .finally(() => {
        setIsLoadingUsers(false);
      });
  }, []);

  const fetchOrders = useCallback(async () => {
    setIsLoadingOrders(true);
    const startDate = selectedDateRange?.startDate?.toString();
    const endDate = selectedDateRange?.endDate?.toString();

    if (!startDate && !endDate) return;

    const url = new URL(`${process.env.REACT_APP_BACKEND_URL}/orders/listall`);
    url.searchParams.append('startDate', startDate);
    url.searchParams.append('endDate', endDate);

    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error('Failed to fetch orders');
      }
      const ordersData = await response.json();
      if (ordersData.success) {
        setArtifyOrders(ordersData.orders);
      } else {
        console.error('Error: Request to fetch orders was not successful.');
      }
    } catch (error) {
      console.error('Error fetching orders:', error);
    } finally {
      setIsLoadingOrders(false);
    }
  }, [selectedDateRange]);

  const refreshData = useCallback((dataType) => {
    if (dataType === 'users') {
      fetchUsers();
    } else if (dataType === 'orders') {
      fetchOrders();
    }
  }, [fetchUsers, fetchOrders]);

  useEffect(() => {
    fetchUsers();
    fetchOrders();
  }, [fetchUsers, fetchOrders]);

  const searchOrder = async (searchOrderRef) => {
    const orderId = searchOrderRef.current.value;
    if (!orderId) {
      console.log('Please enter an order ID.');
      return;
    }

    try {
      setIsLoadingOrders(true);
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/orders/${orderId}/list`);
      const data = await response.json();
      setArtifyOrders([data.orderData]); // Update state with the order data
      setOrderStatus(data.orderData.orderHistory[data.orderData.orderHistory.length - 1].status);
      setIsLoadingOrders(false);
    } catch (error) {
      console.error('Error fetching order data:', error);
    }
  };

  return (
    <AdminDataContext.Provider value={{ artifyUsers, isLoadingUsers, artifyOrders, isLoadingOrders, selectedDateRange, setSelectedDateRange, refreshData, searchOrder, orderStatus, setOrderStatus }}>
      {children}
    </AdminDataContext.Provider>
  );
};
