// Import the Firebase configuration from firebaseConfig.js
import firebaseConfig from "./firebaseConfig";
import { initializeApp } from "firebase/app";
import { getAnalytics,logEvent } from "firebase/analytics";
import { getStorage } from "firebase/storage";
import { getDatabase } from "firebase/database";
import { getFirestore } from "firebase/firestore";
import { getAuth, GoogleAuthProvider, signInWithPopup, signInWithEmailAndPassword, createUserWithEmailAndPassword, sendPasswordResetEmail, signOut, sendEmailVerification, updateProfile, getAdditionalUserInfo } from "firebase/auth";

// Initialize Firebase app with the provided configuration
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const storage = getStorage(app);
const firestore = getFirestore(app);
const realtimeDatabase = getDatabase(app);
const auth = getAuth(app);

// Export Firestore and Auth instances to be used in other files
export {
  analytics,
  logEvent,
  storage,
  firestore,
  realtimeDatabase,
  auth,
  signInWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithPopup,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
  sendEmailVerification,
  updateProfile,
  getAdditionalUserInfo
};
