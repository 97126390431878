import { useState, useEffect, useCallback } from "react";

export default function Carousel({ slides }) {
    const [current, setCurrent] = useState(0);

    const previousSlide = () => {
        setCurrent((current - 1 + slides.length) % slides.length);
    };

    const nextSlide = useCallback(() => {
        setCurrent((current + 1) % slides.length);
    }, [current, slides.length]);

    useEffect(() => {
        const intervalId = setInterval(() => {
            nextSlide();
        }, 4000);

        return () => clearInterval(intervalId);
    }, [current, slides.length, nextSlide]);

    return (
        <div className="absolute inset-0">
            <div className="w-full xl:w-4/5 h-full mx-auto border-8 rounded-3xl overflow-hidden">
                <div className="w-full h-full relative">
                    <div className="w-full h-full flex transition ease-out duration-300" style={{ transform: `translateX(-${current * 100}%)` }}>
                        {slides.map((slide, index) => (
                            slide.endsWith('.mp4') ? (
                                <video key={index} src={slide} className="shrink-0 w-full object-cover" autoPlay loop muted />
                            ) : (
                                <img src={slide} key={index} alt={`slide-${index}`} className="shrink-0 w-full object-cover" />
                            )
                        ))}
                    </div>

                    <div className="absolute top-0 h-full w-full flex items-center justify-between px-4">
                        <button onClick={previousSlide} className="bg-white bg-opacity-70 text-gray-600 w-12 h-12 inline-flex items-center justify-center rounded-full">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5" />
                            </svg>
                        </button>
                        <button onClick={nextSlide} className="bg-white bg-opacity-70 text-gray-600 w-12 h-12 inline-flex items-center justify-center rounded-full">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
            <div className="flex justify-center w-full py-2 mt-6">
                {slides.map((_, index) => (
                    <div key={index} className={`h-1 cursor-pointer rounded-lg ${index === current ? 'w-6 bg-gray-400 scale-150 mx-2' : 'bg-gray-200 w-5 mx-1'} transition-all ease-in-out duration-300`} onClick={() => setCurrent(index)} />
                ))}
            </div>
        </div>
    );
}
