// ManageProducts.jsx
import React, { useState, useRef, useEffect } from 'react';
import { fabric } from 'fabric';
import { useProducts } from '../contexts/ProductsContext';

const ImageCanvas = ({ image, printAreaData }) => {
    const canvasRef = useRef(null);
    const fabricCanvasRef = useRef(null);
    const printAreaRectRef = useRef(null);

    useEffect(() => {
        if (image) {
            const canvas = canvasRef.current;
            if (!canvas) return;

            const fabricCanvas = new fabric.Canvas(canvas, {
                width: canvas.clientWidth,
                height: canvas.clientHeight
            });

            fabricCanvasRef.current = fabricCanvas;

            return () => {
                fabricCanvas.dispose();
            };
        }
    }, [image]);

    useEffect(() => {
        const fabricCanvas = fabricCanvasRef.current;
        if (!fabricCanvas || !printAreaData || !image) return;

        const loadImage = (url) => {
            fabric.Image.fromURL(url, (img) => {
                const scaleFactor = Math.max(fabricCanvas.width / img.width, fabricCanvas.height / img.height);
                img.scale(scaleFactor);
                img.set({
                    originX: 'center',
                    originY: 'center',
                    left: fabricCanvas.width / 2,
                    top: fabricCanvas.height / 2
                });

                fabricCanvas.setBackgroundImage(img, fabricCanvas.renderAll.bind(fabricCanvas), {
                    scaleX: scaleFactor,
                    scaleY: scaleFactor,
                    originX: 'center',
                    originY: 'center'
                });

                // Remove existing print area rectangle if any
                fabricCanvas.getObjects('rect').forEach((obj) => {
                    fabricCanvas.remove(obj);
                });

                // Convert print area data to numbers
                const printAreaWidthPercentage = parseFloat(printAreaData.printAreaWidthPercentage / 100);
                const printAreaHeightPercentage = parseFloat(printAreaData.printAreaHeightPercentage / 100);
                const offsetLeft = parseFloat(printAreaData.offsetLeft);
                const offsetTop = parseFloat(printAreaData.offsetTop);

                // Calculate print area dimensions based on canvas size and scaled image size
                const printAreaWidth = img.width * scaleFactor * printAreaWidthPercentage;
                const printAreaHeight = img.height * scaleFactor * printAreaHeightPercentage;
                const printAreaLeft = (fabricCanvas.width - img.width * scaleFactor) / 2 + (img.width * scaleFactor - printAreaWidth) / 2 + offsetLeft;
                const printAreaTop = (fabricCanvas.height - img.height * scaleFactor) / 2 + (img.height * scaleFactor - printAreaHeight) / 2 + offsetTop;

                const printAreaRect = new fabric.Rect({
                    left: printAreaLeft,
                    top: printAreaTop,
                    width: printAreaWidth,
                    height: printAreaHeight,
                    fill: 'transparent',
                    stroke: printAreaData.stroke,
                    strokeWidth: 2,
                    strokeDashArray: [5, 5],
                    selectable: false
                });

                printAreaRectRef.current = printAreaRect;

                // Add the print area rectangle to the canvas
                fabricCanvas.add(printAreaRect);
                printAreaRect.bringToFront();

                fabricCanvas.renderAll();
            });
        };

        if (typeof image === 'string') {
            // If image is a URL
            loadImage(image);
        } else if (image instanceof File) {
            // If image is a file
            const reader = new FileReader();
            reader.onload = (e) => {
                loadImage(e.target.result);
            };
            reader.readAsDataURL(image);
        }
    }, [printAreaData, image]);

    return (
        image && <canvas ref={canvasRef} className="w-full h-[300px] rounded-2xl border-2 border-dotted" />
    );
};

const ModalBox = ({ modal, closeModalBox, productData, handleProductDataChange, handleCreateProduct, handleUpdateProduct, variantData, setVariantData, handleVariantDataChange, handleCreateVariant, handleUpdateVarinat }) => {
    const productImageRef = useRef();
    const variantImageRef = useRef();
    return (modal.visibility && (
        <div className='fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-6 z-40'>
            <div className={`bg-white p-4 rounded-2xl relative ${modal.modalType === 'manageProducts' ? 'w-full h-full xl:w-1/2 xl:h-fit' : 'w-full xl:w-1/2 h-full xl:h-3/4'}`}>
                <div className='absolute -right-2 -top-2 w-8 h-8 bg-black text-white font-semibold flex items-center justify-center rounded-full cursor-pointer' onClick={closeModalBox}>X</div>
                <div className='w-full h-full overflow-y-auto overflow-x-hidden hide-scrollbar'>
                    {modal.modalType === 'manageProducts' && (
                        <form onSubmit={productData.id ? handleUpdateProduct : handleCreateProduct} className='grid grid-cols-1 xl:grid-cols-2 gap-6'>
                            {/* Product fields */}
                            <img src={productData.thumbnail || 'https://t3.ftcdn.net/jpg/02/48/42/64/360_F_248426448_NVKLywWqArG2ADUxDq6QprtIzsF82dMF.jpg'} id='thumbnailImg' alt="Thumbnail" className={`border border-dashed w-full h-full max-h-[400px] rounded-xl object-contain`} onClick={(e) => { if (!productData.id) { e.preventDefault(); productImageRef.current.click(); } }} />
                            <div>
                                <h2 className='font-semibold antialiased'>{productData.id ? 'Edit' : 'Create'} Product</h2>
                                <input ref={productImageRef} type="file" name="thumbnail" id="thumbnail" accept=".jpg, .jpeg, .png" onChange={handleProductDataChange} hidden />
                                <input type="text" name="name" value={productData.name} onChange={handleProductDataChange} placeholder="Product Name" className='mt-4 w-full border-none outline-none px-2 py-3 bg-gray-100 rounded-xl text-sm' />
                                <input type="text" name="category" value={productData.category} onChange={handleProductDataChange} placeholder="Category" className='mt-4 w-full border-none outline-none px-2 py-3 bg-gray-100 rounded-xl text-sm' />
                                <textarea name="shortDescription" id="shortDescription" value={productData.shortDescription} onChange={handleProductDataChange} placeholder="Short Description" className='mt-4 w-full border-none outline-none px-2 py-3 bg-gray-100 rounded-xl text-sm' ></textarea>
                                <input type="text" name="material" value={productData.material} onChange={handleProductDataChange} placeholder="Material" className='mt-4 w-full border-none outline-none px-2 py-3 bg-gray-100 rounded-xl text-sm' />
                                <input type="number" name="averageRating" value={productData.averageRating} onChange={handleProductDataChange} placeholder="Average Rating" className='mt-4 w-full border-none outline-none px-2 py-3 bg-gray-100 rounded-xl text-sm' />
                                <div className='mt-4 text-[10px] text-red-600'>Note: Use this image as a thumbnail, not a product image.</div>
                                <button type="submit" className='mt-4 w-full font-semibold border-none outline-none px-2 py-3 rounded-xl text-sm border-lime-400 bg-lime-400 cursor-pointer text-gray-800'>{productData.id ? 'Save Changes' : 'Create Product'}</button>
                            </div>
                        </form>
                    )}

                    {/* Variant form */}
                    {variantData.productId && modal.modalType === 'manageVariants' && (
                        <>
                            <form onSubmit={variantData.id ? handleUpdateVarinat : handleCreateVariant} className='grid grid-cols-1 xl:grid-cols-2 gap-6'>
                                <div className='shrink-0'>
                                    {variantData.image && (<ImageCanvas image={variantData.image} printAreaData={variantData.printArea} />)}
                                    {!variantData.image && <img src='https://t3.ftcdn.net/jpg/02/48/42/64/360_F_248426448_NVKLywWqArG2ADUxDq6QprtIzsF82dMF.jpg' alt='placeholer' className='w-full h-[300px] object-contain' onClick={(e) => { if (!variantData.id) { e.preventDefault(); variantImageRef.current.click(); } }} />}
                                    <div className='mt-2 grid grid-cols-3 gap-4'>
                                        <div className='relative border rounded p-2'>
                                            <span className='absolute -top-2 left-2 text-[10px] text-gray-500 bg-white'>Stroke</span>
                                            <input type="text" name="printArea.stroke" value={variantData.printArea.stroke} onChange={handleVariantDataChange} placeholder="Stroke" className='w-full h-full border-none outline-none rounded text-xs capitalize' />
                                        </div>
                                        <div className='relative border rounded p-2'>
                                            <span className='absolute -top-2 left-2 text-[10px] text-gray-500 bg-white'>Offset Left (Units)</span>
                                            <input type="number" name="printArea.offsetLeft" value={variantData.printArea.offsetLeft} onChange={handleVariantDataChange} placeholder="Offset Left" className='w-full h-full border-none outline-none rounded text-xs' step={1} />
                                        </div>
                                        <div className='relative border rounded p-2'>
                                            <span className='absolute -top-2 left-2 text-[10px] text-gray-500 bg-white'>Offset Top (Units)</span>
                                            <input type="number" name="printArea.offsetTop" value={variantData.printArea.offsetTop} onChange={handleVariantDataChange} placeholder="Offset Top" className='w-full h-full border-none outline-none rounded text-xs' step={1} />
                                        </div>
                                    </div>

                                    <div className='mt-4 grid grid-cols-2 gap-4'>
                                        <div className='relative border rounded p-2'>
                                            <span className='absolute -top-2 left-2 text-[10px] text-gray-500 bg-white'>PrintArea Width (Units)</span>
                                            <input type="number" name="printArea.printAreaWidthPercentage" value={variantData.printArea.printAreaWidthPercentage} onChange={handleVariantDataChange} placeholder="Print Area Width" className='w-full h-full border-none outline-none rounded text-xs' step={1} />
                                        </div>
                                        <div className='relative border rounded p-2'>
                                            <span className='absolute -top-2 left-2 text-[10px] text-gray-500 bg-white'>PrintArea Height (Units)</span>
                                            <input type="number" name="printArea.printAreaHeightPercentage" value={variantData.printArea.printAreaHeightPercentage} onChange={handleVariantDataChange} placeholder="Print Area Height" className='w-full h-full border-none outline-none rounded text-xs' step={1} />
                                        </div>
                                    </div>

                                    <div className='mt-4 grid grid-cols-2 gap-4'>
                                        <div className='relative border rounded p-2'>
                                            <span className='absolute -top-2 left-2 text-[10px] text-gray-500 bg-white'>Real Width (cms)</span>
                                            <input type="number" name="printArea.productRealWidth" value={variantData.printArea.productRealWidth} onChange={handleVariantDataChange} placeholder="Real Width" className='w-full h-full border-none outline-none rounded text-xs' step={1} />
                                        </div>
                                        <div className='relative border rounded p-2'>
                                            <span className='absolute -top-2 left-2 text-[10px] text-gray-500 bg-white'>Real Height (cms)</span>
                                            <input type="number" name="printArea.productRealHeight" value={variantData.printArea.productRealHeight} onChange={handleVariantDataChange} placeholder="Real Height" className='w-full h-full border-none outline-none rounded text-xs' step={1} />
                                        </div>
                                    </div>

                                    <div className='mt-4 grid grid-cols-2 gap-4'>
                                        <div className='relative border rounded p-2'>
                                            <span className='absolute -top-2 left-2 text-[10px] text-gray-500 bg-white'>Left Margin (cms)</span>
                                            <input type="number" name="printArea.productRealLeft" value={variantData.printArea.productRealLeft} onChange={handleVariantDataChange} placeholder="Left Margin" className='w-full h-full border-none outline-none rounded text-xs' step={1} />
                                        </div>
                                        <div className='relative border rounded p-2'>
                                            <span className='absolute -top-2 left-2 text-[10px] text-gray-500 bg-white'>Top Margin (cms)</span>
                                            <input type="number" name="printArea.productRealTop" value={variantData.printArea.productRealTop} onChange={handleVariantDataChange} placeholder="Top Margin" className='w-full h-full border-none outline-none rounded text-xs' step={1} />
                                        </div>
                                    </div>

                                    <div className='mt-4 grid grid-cols-2 gap-4'>
                                        <div className='relative border rounded p-2'>
                                            <span className='absolute -top-2 left-2 text-[10px] text-gray-500 bg-white'>Real PrintArea Width (cms)</span>
                                            <input type="number" name="printArea.productRealPrintAreaWidth" value={variantData.printArea.productRealPrintAreaWidth} onChange={handleVariantDataChange} placeholder="Real PrintArea Width" className='w-full h-full border-none outline-none rounded text-xs' step={1} />
                                        </div>
                                        <div className='relative border rounded p-2'>
                                            <span className='absolute -top-2 left-2 text-[10px] text-gray-500 bg-white'>Real PrintArea Height (cms)</span>
                                            <input type="number" name="printArea.productRealPrintAreaHeight" value={variantData.printArea.productRealPrintAreaHeight} onChange={handleVariantDataChange} placeholder="Real PrintArea Height" className='w-full h-full border-none outline-none rounded text-xs' step={1} />
                                        </div>
                                    </div>
                                </div>
                                {/* Variant fields */}
                                <div className='grid grid-cols-1 h-fit'>
                                    <h2 className='font-semibold antialiased'>{variantData.id ? 'Edit Variant' : 'Create Variant'}</h2>
                                    <input ref={variantImageRef} type="file" accept=".jpg, .jpeg, .png" name="image" onChange={handleVariantDataChange} hidden />
                                    <div className='mt-6 relative border rounded p-2'>
                                        <span className='absolute -top-2 left-2 text-[10px] text-gray-500 bg-white'>Variant Name</span>
                                        <input type="text" name="name" value={variantData.name} onChange={handleVariantDataChange} placeholder="Variant Name" className='w-full h-full border-none outline-none rounded text-xs' />
                                    </div>

                                    <div className='mt-6 grid grid-cols-2 gap-2'>
                                        <div className='relative border rounded p-2'>
                                            <span className='absolute -top-2 left-2 text-[10px] text-gray-500 bg-white'>Available Sizes</span>
                                            <input type="text" name="sizes" value={variantData.sizes} onChange={handleVariantDataChange} placeholder="Available Sizes" className='w-full h-full border-none outline-none rounded text-xs' />
                                        </div>
                                        <div className='relative border rounded p-2'>
                                            <span className='absolute -top-2 left-2 text-[10px] text-gray-500 bg-white'>Variant Color</span>
                                            <input type="text" name="color" value={variantData.color} onChange={handleVariantDataChange} placeholder="Variant Color" className='w-full h-full border-none outline-none rounded text-xs' />
                                        </div>
                                    </div>

                                    <div className='mt-6 grid grid-cols-2 gap-2'>
                                        <div className='relative border rounded p-2'>
                                            <span className='absolute -top-2 left-2 text-[10px] text-gray-500 bg-white'>HexColorCode</span>
                                            <input type="text" name="HexColorCode" value={variantData.HexColorCode} onChange={handleVariantDataChange} placeholder="HexColorCode" className='w-full h-full border-none outline-none rounded text-xs' />
                                        </div>
                                        <div className='relative border rounded p-2'>
                                            <span className='absolute -top-2 left-2 text-[10px] text-gray-500 bg-white'>Gender</span>
                                            <input type="text" name="gender" value={variantData.gender} onChange={handleVariantDataChange} placeholder="Gender" className='w-full h-full border-none outline-none rounded text-xs' />
                                        </div>
                                    </div>

                                    <div className='mt-6 grid grid-cols-2 gap-2'>
                                        <div className='relative border rounded p-2'>
                                            <span className='absolute -top-2 left-2 text-[10px] text-gray-500 bg-white'>Price</span>
                                            <input type="text" name="price" value={variantData.price} onChange={handleVariantDataChange} placeholder="Price" className='w-full h-full border-none outline-none rounded text-xs' />
                                        </div>
                                        <div className='relative border rounded p-2'>
                                            <span className='absolute -top-2 left-2 text-[10px] text-gray-500 bg-white'>Stock</span>
                                            <input type="text" name="stock" value={variantData.stock} onChange={handleVariantDataChange} placeholder="Stock" className='w-full h-full border-none outline-none rounded text-xs' />
                                        </div>
                                    </div>

                                    <div className='mt-6 relative border rounded p-2'>
                                        <span className='absolute -top-2 left-2 text-[10px] text-gray-500 bg-white'>Description</span>
                                        <textarea name="description" rows={10} value={variantData.description} onChange={handleVariantDataChange} placeholder="Description" className='w-full h-full border-none outline-none rounded text-xs'></textarea>
                                    </div>

                                    <button type="submit" className='mt-4 text-xs px-4 py-3 border rounded-lg font-semibold inline-flex items-center justify-between gap-2 border-lime-400 bg-lime-400 cursor-pointer text-gray-800'>{variantData.id ? 'Save Changes' : 'Create Variant'}</button>
                                </div>
                            </form>

                            <h2 className='mt-4 font-semibold antialiased'>Total Variants</h2>
                            <div className='inline-flex items-center gap-4 mb-4 flex-wrap'>
                                {productData.variants.map((id, index) => (
                                    <div key={index} className={`mt-4 inline-flex items-center gap-2 shadow rounded-md px-2 text-[10px] ${variantData.id ? (variantData.id === id ? 'bg-white' : 'bg-gray-100 cursor-not-allowed') : ''}`} onClick={(e) => { if (!variantData.id) { e.preventDefault(); setVariantData(prev => ({ ...prev, id: id })) } }}>
                                        <span className='bg-black text-white w-4 h-4 rounded-full inline-flex items-center justify-center'>{index + 1}</span>
                                        <span className='p-2'>{id}</span>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="size-3">
                                            <path d="m5.433 13.917 1.262-3.155A4 4 0 0 1 7.58 9.42l6.92-6.918a2.121 2.121 0 0 1 3 3l-6.92 6.918c-.383.383-.84.685-1.343.886l-3.154 1.262a.5.5 0 0 1-.65-.65Z" />
                                            <path d="M3.5 5.75c0-.69.56-1.25 1.25-1.25H10A.75.75 0 0 0 10 3H4.75A2.75 2.75 0 0 0 2 5.75v9.5A2.75 2.75 0 0 0 4.75 18h9.5A2.75 2.75 0 0 0 17 15.25V10a.75.75 0 0 0-1.5 0v5.25c0 .69-.56 1.25-1.25 1.25h-9.5c-.69 0-1.25-.56-1.25-1.25v-9.5Z" />
                                        </svg>
                                    </div>
                                ))}
                            </div>
                            <div className='text-[10px] text-red-600'>Note: One Variant can be edited at a time. In order to edit another variant close the Modal Box or Save Current Variant Details</div>
                        </>
                    )}
                </div>
            </div>
        </div>
    )
    );
}

export default function ManageProducts() {
    const { products, isLoadingProducts, refreshProducts } = useProducts();

    const [modal, showModal] = useState({ visibility: false, modalType: null });

    const [productData, setProductData] = useState({
        name: '',
        thumbnail: null,
        category: '',
        shortDescription: '',
        material: '',
        averageRating: 0,
        variants: [] // State to hold variants
    });

    const handleProductDataChange = (e) => {
        const { name, value, type } = e.target;

        if (type === 'file') {
            const file = e.target.files[0];
            if (file) {
                // Assuming you want to store the file object itself in the state
                setProductData(prevState => ({
                    ...prevState,
                    [name]: file
                }));

                // Create a FileReader instance to read the file
                const reader = new FileReader();

                // Define the onLoad function to execute when reading is completed
                reader.onload = (event) => {
                    // Set the src attribute of the img tag to the data URL of the file
                    document.getElementById('thumbnailImg').src = event.target.result;
                };

                // Start reading the file as a data URL
                reader.readAsDataURL(file);
            }
        } else {
            // For non-file inputs
            setProductData(prevState => ({
                ...prevState,
                [name]: value
            }));
        }
    };

    const handleCreateProduct = async (e) => {
        e.preventDefault();
        try {
            const formData = new FormData();

            // Append product data to the FormData object
            Object.entries(productData).forEach(([key, value]) => {
                formData.append(key, value);
            });

            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/products/products`, {
                method: 'POST',
                headers: {
                    // Add any additional headers if needed
                },
                body: formData
            });

            if (!response.ok) {
                throw new Error('Failed to create product');
            }

            const responseData = await response.json();
            if (responseData.success) {
                // Reset form after successful creation if needed
                setProductData({
                    name: '',
                    thumbnail: null,
                    category: '',
                    shortDescription: '',
                    material: '',
                    averageRating: 0,
                    variants: []
                });
                await refreshProducts();
                closeModalBox();
            }

        } catch (error) {
            console.error('Error creating product:', error);
        }
    };

    const handleUpdateProduct = async (e) => {
        try {
            e.preventDefault();
            const formData = new FormData();

            // Append product data to the FormData object
            Object.entries(productData).forEach(([key, value]) => {
                if (key === 'variants') {
                    // If the key is 'sizes' or 'printArea', stringify it
                    formData.append(key, JSON.stringify(value));
                } else {
                    // For non-object properties, append them directly
                    formData.append(key, value);
                }
            });

            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/products/products/${productData.id}`, {
                method: 'PUT',
                headers: {
                    // Add any additional headers if needed
                },
                body: formData
            });

            if (!response.ok) {
                throw new Error('Failed to update product');
            }

            const responseData = await response.json();
            if (responseData.success) {
                closeModalBox();
                await refreshProducts();
            }
        } catch (error) {
            console.error(error)
        }
    }

    const [variantData, setVariantData] = useState({
        productId: '',
        sizes: [],
        color: '',
        gender: '',
        price: '',
        name: '',
        description: '',
        stock: '',
        HexColorCode: '',
        image: null,
        printArea: {
            stroke: 'black',
            offsetLeft: -5,
            offsetTop: 50,
            printAreaWidthPercentage: 35,
            printAreaHeightPercentage: 50,
            productRealWidth: 0,
            productRealHeight: 0,
            productRealLeft: 0,
            productRealTop: 0,
            productRealPrintAreaWidth: 0,
            productRealPrintAreaHeight: 0,
        }
    });

    const handleVariantDataChange = (e) => {
        const { name, value, files } = e.target;

        if (name === 'image') {
            // Handle the image field separately
            setVariantData(prevState => ({
                ...prevState,
                image: files[0]
            }));
        } else if (name === 'sizes') {
            // Handle sizes field to store it as an array
            const sizesArray = value.split(',').map(size => size.trim());
            setVariantData(prevState => ({
                ...prevState,
                sizes: sizesArray
            }));
        } else if (name.includes('.')) {
            // Check if the name contains a dot, indicating a nested object
            const [parent, child] = name.split('.');
            setVariantData(prevState => ({
                ...prevState,
                [parent]: {
                    ...prevState[parent],
                    [child]: value
                }
            }));
        } else {
            // If not nested, update directly
            setVariantData(prevState => ({
                ...prevState,
                [name]: value
            }));
        }
    };

    const handleCreateVariant = async (e) => {
        try {
            e.preventDefault();
            const formData = new FormData();

            // Append product data to the FormData object
            Object.entries(variantData).forEach(([key, value]) => {
                if (key === 'sizes' || key === 'printArea') {
                    // If the key is 'sizes' or 'printArea', stringify it
                    formData.append(key, JSON.stringify(value));
                } else {
                    // For non-object properties and 'image', append them directly
                    formData.append(key, value);
                }
            });

            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/products/products/${variantData.productId}/variants`, {
                method: 'POST',
                headers: {
                    // Add any additional headers if needed
                },
                body: formData
            });

            if (!response.ok) {
                throw new Error('Failed to create product variant');
            }

            const responseData = await response.json();
            if (responseData.success) {
                setVariantData({
                    sizes: [],
                    color: '',
                    gender: '',
                    price: '',
                    name: '',
                    description: '',
                    stock: '',
                    HexColorCode: '',
                    image: null,
                    printArea: {
                        stroke: 'black',
                        offsetLeft: -5,
                        offsetTop: 50,
                        printAreaWidthPercentage: 35,
                        printAreaHeightPercentage: 50,
                        productRealWidth: 0,
                        productRealHeight: 0,
                        productRealLeft: 0,
                        productRealTop: 0,
                        productRealPrintAreaWidth: 0,
                        productRealPrintAreaHeight: 0,
                    }
                })
                await refreshProducts();
                closeModalBox();
            }
        } catch (error) {
            console.error('Error creating product variant:', error);
        }
    };

    const handleUpdateVarinat = async (e) => {
        try {
            e.preventDefault();
            const formData = new FormData();

            // Append product data to the FormData object
            Object.entries(variantData).forEach(([key, value]) => {
                if (key === 'id' || key === 'image') {
                    // Skip 'id' and 'image'
                    return;
                }
                if (key === 'sizes' || key === 'printArea') {
                    // If the key is 'sizes' or 'printArea', stringify it
                    formData.append(key, JSON.stringify(value));
                } else {
                    // For non-object properties, append them directly
                    formData.append(key, value);
                }
            });

            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/products/variants/${variantData.id}`, {
                method: 'PUT',
                headers: {
                    // Add any additional headers if needed
                },
                body: formData
            });

            if (!response.ok) {
                throw new Error('Failed to update product variant');
            }

            const responseData = await response.json();
            if (responseData.success) {
                closeModalBox();
                console.log(responseData)
            }
        } catch (error) {
            console.error(error)
        }
    }

    const closeModalBox = () => {
        showModal({ visibility: false, modalType: null });
        setProductData({
            name: '',
            thumbnail: null,
            category: '',
            shortDescription: '',
            material: '',
            averageRating: 0,
            variants: [] // State to hold variants
        })
        setVariantData({
            productId: '',
            sizes: [],
            color: '',
            gender: '',
            price: '',
            name: '',
            description: '',
            stock: '',
            HexColorCode: '',
            image: null,
            printArea: {
                stroke: 'black',
                offsetLeft: -5,
                offsetTop: 50,
                printAreaWidthPercentage: 35,
                printAreaHeightPercentage: 50,
                productRealWidth: 0,
                productRealHeight: 0,
                productRealLeft: 0,
                productRealTop: 0,
                productRealPrintAreaWidth: 0,
                productRealPrintAreaHeight: 0,
            }
        })
    }

    useEffect(() => {
        const fetchVariantData = async () => {
            if (!variantData.id) return;

            const url = `${process.env.REACT_APP_BACKEND_URL}/products/variants/${variantData.id}`;

            try {
                const response = await fetch(url);
                if (!response.ok) {
                    throw new Error(`Error fetching variant: ${response.statusText}`);
                }
                const data = await response.json();
                setVariantData(data.variant);
            } catch (err) {
                console.error(err.message);
            }
        };

        fetchVariantData();
    }, [variantData.id]);

    return (
        <div>
            {isLoadingProducts && (
                <div className='h-[150px] flex items-center justify-center'>
                    <div role="status">
                        <svg aria-hidden="true" className="inline w-4 h-4 text-black animate-spin fill-white" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                        </svg>
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            )}
            {!isLoadingProducts && (
                <div className="w-full min-h-[530px] relative flex flex-col bg-clip-border rounded-xl bg-white text-gray-700 shadow-md overflow-hidden xl:col-span-2">
                    <div className="relative bg-clip-border rounded-xl bg-transparent text-gray-700 shadow-none m-0 flex flex-wrap items-center justify-between gap-2 px-6 py-4">
                        <div className='shrink-0'>
                            <h6 className="block antialiased tracking-normal font-sans text-base font-semibold leading-relaxed text-blue-gray-900 mb-1">Products</h6>
                            <p className="antialiased font-sans text-sm leading-normal flex items-center gap-1 font-normal text-blue-gray-600">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="3" stroke="currentColor" aria-hidden="true" className="h-4 w-4 text-blue-500">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5"></path>
                                </svg>
                                <strong>{products.length}</strong> in total
                            </p>
                        </div>
                        {/* Add your button here if needed */}
                        <button className='shrink-0 text-xs px-4 py-3 border rounded-lg font-semibold inline-flex items-center justify-between gap-2 border-lime-400 bg-lime-400 cursor-pointer text-gray-800' onClick={() => { showModal({ visibility: true, modalType: 'manageProducts' }) }}>Create Product</button>
                    </div>
                    <div className="overflow-x-scroll px-0 pt-0 hide-scrollbar">
                        <table className="w-full min-w-[640px] table-auto">
                            <thead>
                                <tr>
                                    <th className="border-b border-blue-gray-50 py-3 px-6 text-left">
                                        <p className="block antialiased font-sans text-[11px] font-medium uppercase text-blue-gray-400 truncate">Product</p>
                                    </th>
                                    <th className="border-b border-blue-gray-50 py-3 px-6 text-left">
                                        <p className="block antialiased font-sans text-[11px] font-medium uppercase text-blue-gray-400">Category</p>
                                    </th>
                                    <th className="border-b border-blue-gray-50 py-3 px-6 text-left">
                                        <p className="block antialiased font-sans text-[11px] font-medium uppercase text-blue-gray-400">Material</p>
                                    </th>
                                    <th className="border-b border-blue-gray-50 py-3 px-6 text-left">
                                        <p className="block antialiased font-sans text-[11px] font-medium uppercase text-blue-gray-400 truncate">Total Variants</p>
                                    </th>
                                    <th className="border-b border-blue-gray-50 py-3 px-6 text-left">
                                        <p className="block antialiased font-sans text-[11px] font-medium uppercase text-blue-gray-400">Actions</p>
                                    </th>
                                    {/* Add more table headers as needed */}
                                </tr>
                            </thead>
                            <tbody>
                                {products.map((product, index) => (
                                    <tr key={index} className='border-b border-blue-gray-50'>
                                        <td className='py-3 px-5 flex items-center gap-4'>
                                            <img
                                                src={product.thumbnail}
                                                alt={product.name}
                                                className="w-8 h-8 rounded-full"
                                            />
                                            <p className="block antialiased font-sans text-sm leading-normal text-blue-gray-900 font-bold truncate">{product.name}</p>
                                        </td>

                                        <td className="py-3 px-5">
                                            <p className="block antialiased font-sans text-xs font-medium text-blue-gray-600 capitalize">{product.category}</p>
                                        </td>

                                        <td className="py-3 px-5">
                                            <p className="block antialiased font-sans text-xs font-medium text-blue-gray-600 capitalize">{product.material}</p>
                                        </td>

                                        <td className="py-3 px-5 truncate">
                                            <p className="inline antialiased font-sans text-xs font-medium text-blue-gray-600 capitalize">{product?.variants?.length || 0}</p>
                                            &nbsp;&nbsp;
                                            <button className=' inline font-sans text-[11px] font-medium text-blue-gray-600 capitalize p-1 bg-lime-200 rounded-sm' onClick={() => { setProductData(product); setVariantData(prev => ({ ...prev, productId: product.id })); showModal({ visibility: true, modalType: 'manageVariants' }); }}>Manage Variants</button>
                                        </td>

                                        <td className="py-3 px-5 cursor-pointer" onClick={() => { setProductData(product); showModal({ visibility: true, modalType: 'manageProducts' }) }}>
                                            <p className="block antialiased font-sans text-xs font-medium text-blue-gray-600 capitalize">Edit</p>
                                        </td>

                                        {/* Add more table data cells as needed */}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            )}
            <ModalBox modal={modal} closeModalBox={closeModalBox} productData={productData} setProductData={setProductData} handleProductDataChange={handleProductDataChange} handleUpdateProduct={handleUpdateProduct} handleCreateProduct={handleCreateProduct} variantData={variantData} setVariantData={setVariantData} handleVariantDataChange={handleVariantDataChange} handleCreateVariant={handleCreateVariant} handleUpdateVarinat={handleUpdateVarinat} />
        </div>
    );
}