import React, { useState, useEffect, useMemo } from 'react'
import { useParams, useLocation } from "react-router-dom";
// Contexts
import { useProducts } from '../contexts/ProductsContext';
import { useAuthenticate } from '../contexts/AuthenticateContext';
import { useRestyledImages } from '../contexts/RestyledImagesContext';
import { useCart } from '../contexts/CartProvider';
import { useTopUpCredits } from '../contexts/TopUpCreditsContext';

import DesignEditor from '../components/DesignEditor';
import UserGenerations from '../components/UserGenerations';
import Cart from '../components/Cart';
import Authenticate from '../components/Authenticate';
import TopUpCredits from '../components/TopUpCredits';

export default function ProductDetails() {
    // Get the parameters from the URL
    let { productId } = useParams();

    const location = useLocation();

    const availableFilter = useMemo(() => [
        { filterID: 'Vx2sa2A', filterName: 'Pixar Style', filterImage: 'https://firebasestorage.googleapis.com/v0/b/image-restyle.appspot.com/o/assets%2F1.jpg?alt=media&token=906f27b8-d868-46f1-b04d-5a5c3426d735' },
        { filterID: 'Lp9hRc', filterName: 'Modern Anime', filterImage: 'https://firebasestorage.googleapis.com/v0/b/image-restyle.appspot.com/o/assets%2F2.jpg?alt=media&token=9f39947e-2ab3-485a-9bda-7ef8133bf432' },
        { filterID: 'Zt4rG5', filterName: 'Grand Theft Auto', filterImage: 'https://firebasestorage.googleapis.com/v0/b/image-restyle.appspot.com/o/assets%2F3.jpg?alt=media&token=e2679dd0-0527-4878-ab28-ffe7602f03b8' },
        { filterID: 'Im8pJb', filterName: 'Cybertech', filterImage: 'https://firebasestorage.googleapis.com/v0/b/image-restyle.appspot.com/o/assets%2F4.jpg?alt=media&token=ed0ad6d1-9f52-4374-9b79-c86f2ce811f2' },
        { filterID: 'Mv7Uq9', filterName: 'Oil Painting', filterImage: 'https://firebasestorage.googleapis.com/v0/b/image-restyle.appspot.com/o/assets%2F5.jpg?alt=media&token=d8f4cd35-f6bc-47bd-b534-af4e2655cb3f' },
        { filterID: 'Yw8KpF', filterName: 'Oldschool Comic', filterImage: 'https://firebasestorage.googleapis.com/v0/b/image-restyle.appspot.com/o/assets%2F6.jpg?alt=media&token=a898e8d2-d5e0-479d-86dc-b27e37e4bb20' },
        { filterID: 'Bn1pRd', filterName: 'Retro', filterImage: 'https://firebasestorage.googleapis.com/v0/b/image-restyle.appspot.com/o/assets%2F7.jpg?alt=media&token=03ce3319-925f-4526-aca2-79912db0f4e5' },
        { filterID: 'Tk2wRc', filterName: 'Glowwave', filterImage: 'https://firebasestorage.googleapis.com/v0/b/image-restyle.appspot.com/o/assets%2F8.jpg?alt=media&token=32c3d663-8435-4644-b8a8-a53b7eab664a' },
        { filterID: 'Lg9Tq1', filterName: 'Complex Art', filterImage: 'https://firebasestorage.googleapis.com/v0/b/image-restyle.appspot.com/o/assets%2F9.jpg?alt=media&token=c4a77464-86dc-4999-8228-c9aec09c9f2a' },
        { filterID: 'Dt5pIb', filterName: 'Anime', filterImage: 'https://firebasestorage.googleapis.com/v0/b/image-restyle.appspot.com/o/assets%2F10.jpg?alt=media&token=192c2882-15ab-4933-a223-c195718e5588' },
        { filterID: 'An7eW1', filterName: '3D Mascot', filterImage: 'https://firebasestorage.googleapis.com/v0/b/image-restyle.appspot.com/o/assets%2F11.jpg?alt=media&token=c61b80e7-815a-4c10-9a31-90b299f624d4' },
        { filterID: 'Al0eWf', filterName: 'Ancient Rich', filterImage: 'https://firebasestorage.googleapis.com/v0/b/image-restyle.appspot.com/o/assets%2F12.jpg?alt=media&token=4803941e-412a-445d-9deb-cc3ce2ab02a5' },
        { filterID: 'Hn1goId', filterName: 'Humanoid', filterImage: 'https://firebasestorage.googleapis.com/v0/b/image-restyle.appspot.com/o/assets%2F13.jpg?alt=media&token=a4f7dcb3-46e8-4ed8-9885-ec8bcdfdb439' },
        { filterID: 'El1xf', filterName: 'Elf', filterImage: 'https://cdn.leonardo.ai/users/b8a384fc-fb0c-4c50-bf8f-77fb1730d593/generations/5a268588-4335-49fd-b574-15e9f6124d55/variations/alchemyrefiner_alchemymagic_1_5a268588-4335-49fd-b574-15e9f6124d55_0.jpg?w=512' },
    ], []);

    const { isTopUpCreditsVisible } = useTopUpCredits();


    // Use the useProducts() hook to access the products array
    const { products, isLoadingProducts } = useProducts();

    // Use the useAuthenticate hook to access authentication context
    const { isLoginFormVisible, authStatus, currentUser, toggleLoginForm } = useAuthenticate();

    // Use the useRestyledImages() hook to access the restyledImages array
    const { restyledImages } = useRestyledImages();
    const [restyledImageKey, setRestyledImageKey] = useState(location?.state?.restyledImageKey);

    const [activeProduct, setActiveProduct] = useState(null);
    const [productVariants, setProductVariants] = useState([]);
    const [activeVariant, setActiveVariant] = useState(null);
    const [loadingVariant, setLoadingVariant] = useState(false);

    useEffect(() => {
        setActiveProduct((productId && products.find(product => product.id === productId)) || products[0]);
    }, [products, productId]);

    useEffect(() => {
        const fetchProductVariants = async () => {
            setLoadingVariant(true); // Set loading state to true before fetching
            try {
                if (!activeProduct) return []; // If no active product, return empty array
                const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/products/products/${activeProduct.id}/variants`);
                if (!response.ok) {
                    throw new Error('Failed to fetch variants');
                }
                const variantsResponse = await response.json();
                return variantsResponse?.variants || []; // Return variants or empty array
            } catch (error) {
                console.error('Error fetching variants:', error);
                return []; // Return empty array in case of error
            } finally {
                setLoadingVariant(false); // Set loading state to false after fetch, whether success or failure
            }
        };

        const setInitialVariants = async () => {
            const variants = await fetchProductVariants();
            setProductVariants(variants); // Update productVariants state with fetched variants
            if (variants.length > 0) {
                setActiveVariant(variants[0]); // Set the first variant as active
            }
        };

        setInitialVariants();
    }, [activeProduct]);

    // Design Editor

    const [designEditorData, setDesignEditorData] = useState(null);

    useEffect(() => {
        setDesignEditorData(prevData => ({
            ...prevData,
            designImage: restyledImages?.[restyledImageKey]?.restyledImageUrl || (prevData?.designImage || null),
            productImage: activeVariant?.image || (prevData?.productImage || null),
            printArea: activeVariant?.printArea || (prevData?.printArea || null),
            text: {
                ...prevData?.text,
                primary: {
                    ...prevData?.text?.primary,
                    text: prevData?.text?.primary?.text || "",
                    color: prevData?.text?.primary?.color || "#FFA503",
                    fontFamily: prevData?.text?.primary?.fontFamily || 'orange-squash'
                },
                secondary: {
                    ...prevData?.text?.secondary,
                    text: prevData?.text?.secondary?.text || "",
                    color: prevData?.text?.secondary?.color || "#008001",
                    fontFamily: prevData?.text?.secondary?.fontFamily || 'orange-squash'
                }
            }
        }));
    }, [activeVariant, restyledImages, restyledImageKey]);

    // Dynamic function to handle changes for text, color, and font size inputs
    const updateDesignEditorTextProperty = (property, value) => {
        setDesignEditorData(prevData => ({
            ...prevData,
            text: {
                ...prevData.text,
                [property]: {
                    ...prevData.text[property],
                    [value.target.name]: value.target.value
                }
            }
        }));
    };

    // User Generation Modal Box
    const [userGenerationsModal, showUserGenerationsModal] = useState(false);

    const selectRestyledImage = (key) => {
        setRestyledImageKey(key);
        showUserGenerationsModal(false);
    }

    // Use the useCart hook to access the cart state and functions

    const { addToCart, toggleCartVisibility } = useCart();

    const [importedCanvasData, setImportedCanvasData] = useState(null);

    return (
        <>
            {isLoginFormVisible && (
                <Authenticate />
            )}

            {isTopUpCreditsVisible && (
                <TopUpCredits />
            )}
            <div className='w-full container mx-auto px-6 xl:px-0'>

                {/* Product Details Grid */}
                {(isLoadingProducts || loadingVariant) && (
                    <div className='h-[650px] flex items-center justify-center'>
                        <div role="status">
                            <svg aria-hidden="true" className="inline w-4 h-4 text-black animate-spin fill-white" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                            </svg>
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                )}

                <div className={`w-full grid-cols-1 grid xl:grid-cols-2 gap-6 overflow-hidden xl:pt-12 ${isLoadingProducts || loadingVariant ? 'hidden' : ''}`}>
                    {/* Grid Item 1 */}
                    <div className='w-full xl:w-4/5 h-fit justify-self-center'>
                        <div className={`relative w-full h-[500px] rounded-2xl flex items-center justify-center cursor-pointer border-2 transition-all ease-in-out duration-500 overflow-hidden ${designEditorData ? '' : 'border-4 hover:bg-slate-50 hover:border-lime-400'}`}>
                            {designEditorData && Object.keys(designEditorData).length > 0 && (
                                <DesignEditor
                                    productImage={designEditorData.productImage}
                                    printAreaData={designEditorData.printArea}
                                    designImage={designEditorData.designImage}
                                    text={designEditorData.text}
                                    exportCanvasData={setImportedCanvasData}
                                />
                            )}
                        </div>

                        <div className='w-full flex items-center justify-center space-x-2 xl:space-x-4 p-4'>
                            {activeProduct && (<img src={activeProduct?.thumbnail} alt="" className='shrink-0 w-24 h-24 object-contain rounded-md border-2 cursor-not-allowed' />)}
                            {activeProduct && (<div className='text-xl font-extrabold text-gray-800'>+</div>)}
                            {restyledImages?.[restyledImageKey]?.originalImageUrl ? <img src={restyledImages?.[restyledImageKey]?.originalImageUrl} alt="" className='shrink-0 w-24 h-24 object-cover rounded-md border-2 p-[2px] cursor-pointer' onClick={() => { showUserGenerationsModal(true) }} /> : <div className='shrink-0 w-24 h-24 rounded-md text-gray-500 flex flex-col items-center justify-center gap-2 border-[1px] text-[10px] cursor-pointer' onClick={() => { !authStatus.isLoading && currentUser ? showUserGenerationsModal(true) : toggleLoginForm() }}>Pick Design</div>}
                            {restyledImages?.[restyledImageKey]?.restyledImageUrl && (<div className='text-xl font-extrabold text-gray-800'>+</div>)}
                            {restyledImages?.[restyledImageKey]?.restyledImageUrl && restyledImages?.[restyledImageKey]?.appliedFilterID && <img src={restyledImages?.[restyledImageKey]?.appliedFilterID && availableFilter.find(filter => filter.filterID === restyledImages[restyledImageKey]?.appliedFilterID)?.filterImage} alt="" className='shrink-0 w-24 h-24 object-cover rounded-md border-2 p-[2px] cursor-not-allowed' />}
                        </div>
                    </div>

                    {/* Grid Item 2 */}
                    {activeProduct && (
                        <div className='w-full min-h-full'>
                            <div className='text-3xl xl:text-5xl font-extrabold text-gray-800 capitalize'>{activeVariant?.name}</div>
                            <div className='mt-4'>
                                <div className='inline-flex items-center'>
                                    {[...Array(Math.round(activeProduct?.averageRating))].map((_, index) => (
                                        <svg key={index} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-3 h-3 inline">
                                            <path fillRule="evenodd" d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z" clipRule="evenodd" />
                                        </svg>
                                    ))}
                                    <span className='text-[11px] ml-1'>{activeProduct?.averageRating} Star Rating</span>
                                </div>
                            </div>

                            <div className='mt-4 inline-flex items-center gap-4'>
                                <div className='font-medium'>$ {activeVariant?.price}</div>
                                <div className='px-4 py-2 rounded-3xl bg-gray-100 w-fit text-xs font-medium'>{activeVariant?.stock > 0 ? 'In Stock' : 'Out Of Stock'}</div>
                            </div>
                            <div className='mt-4 text-gray-600 text-xs'>{activeProduct?.shortDescription}</div>
                            <div className='mt-4 inline-flex items-center flex-wrap gap-6 text-gray-600 text-xs'>
                                <div className=''>Category: {activeProduct?.category}</div>
                                <div>Material: {activeProduct?.material}</div>
                                <div>Color: {activeVariant?.color}</div>
                            </div>

                            {/* Mapping sizes variants to circular divs */}
                            <div className='mt-4 text-sm font-medium'>Select Your Size</div>
                            <div className="mt-4 flex items-center gap-2">
                                {activeVariant && activeVariant?.sizes.map(size => (
                                    <div
                                        key={size}
                                        className={`inline-flex items-center justify-center w-16 h-8 rounded-xl border-2 cursor-pointer ${!activeVariant?.selectedSize ? 'border-red-100' : `${activeVariant?.selectedSize === size ? 'border-lime-400' : ''}`}`}
                                        onClick={() => activeVariant && setActiveVariant(prevVariant => ({ ...prevVariant, selectedSize: size }))}>
                                        <span className="text-black">{size}</span>
                                    </div>
                                ))}
                                {!activeVariant?.selectedSize && (
                                    <span className='text-red-600 text-xs font-medium'>Choose Your Size</span>
                                )}
                            </div>

                            {/* Mapping color variants to circular divs */}
                            <div className="mt-4 flex items-center gap-2">
                                <div className='text-sm font-medium'>Select Color</div>
                                {productVariants
                                    .filter(variant => variant.productId === activeProduct?.id)
                                    .map(variant => (
                                        <div key={variant.id} className={`inline-flex items-center justify-center w-6 h-6 rounded-full border-2 cursor-pointer ${variant.HexColorCode === activeVariant?.HexColorCode ? 'ring-2 ring-black' : ''} transition-all ease-in-out duration-200`} style={{ backgroundColor: variant.HexColorCode }} onClick={() => { setActiveVariant(variant) }}>
                                            <span className="sr-only">{variant.name}</span>
                                        </div>
                                    ))}
                            </div>

                            {designEditorData && Object.keys(designEditorData).length > 0 && Object.keys(designEditorData.text).map((textKey) => (
                                <div key={textKey}>
                                    <div className='mt-4 text-sm font-medium'>{textKey.charAt(0).toUpperCase() + textKey.slice(1)} Text</div>
                                    <div className='mt-4 inline-flex items-center gap-2 xl:gap-4'>
                                        <input type="text" className='w-1/2 px-4 py-2 bg-slate-100 rounded-lg' placeholder='Text' name="text" value={designEditorData.text[textKey].text} onChange={(e) => updateDesignEditorTextProperty(textKey, e)} />
                                        <select name="fontFamily" className="w-fit p-2 text-sm text-gray-600 rounded-lg bg-slate-100 shrink-0 font-medium" value={designEditorData.text[textKey].fontFamily} onChange={(e) => updateDesignEditorTextProperty(textKey, e)}>
                                            <option value=''>Font Family</option>
                                            <option value='orange-squash'>Orange Squash</option>
                                            <option value='super-senior'>Super Senior</option>
                                            <option value='trushdex'>Trushdex</option>
                                        </select>
                                        <input name='color' type="color" className="p-1 h-8 w-12 block bg-white border border-gray-200 cursor-pointer rounded-lg shrink-0" value={designEditorData.text[textKey].color} onChange={(e) => updateDesignEditorTextProperty(textKey, e)} />
                                    </div>
                                </div>
                            ))}

                            <div className='mt-4 text-xs text-gray-600'>{activeVariant?.description}</div>
                            <div className='mt-4 bg-slate-100 p-4 rounded-xl'>
                                <div className='font-medium text-[12px]'>Instructions For Printing</div>
                                <ul className='mt-4 text-[12px] flex flex-col gap-2'>
                                    <li>1. Ensure the design fits within the printable area of the product.</li>
                                    <li>2. Ensure the color of the design resembles the color of the product.</li>
                                </ul>
                            </div>

                            <div className='mt-6 grid grid-cols-1 xl:grid-cols-2 gap-2 w-full'>
                                <div></div>
                                <button className='w-full py-3 font-medium rounded-lg inline-flex items-center justify-center gap-2  border-2 border-black bg-lime-400 text-black hover:bg-black hover:text-white disabled:border-none disabled:bg-gray-200 disabled:text-gray-500 disabled:hover:text-gray-400 disabled:cursor-not-allowed transition-all ease-in-out duration-300' disabled={!restyledImageKey} onClick={() => activeVariant.id && activeVariant.selectedSize && Object.keys(importedCanvasData).length > 0 && (addToCart(activeVariant.id, activeVariant.selectedSize, importedCanvasData), toggleCartVisibility())}>
                                    Add to Cart
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-4 h-4">
                                        <path fillRule="evenodd" d="M7.5 6v.75H5.513c-.96 0-1.764.724-1.865 1.679l-1.263 12A1.875 1.875 0 0 0 4.25 22.5h15.5a1.875 1.875 0 0 0 1.865-2.071l-1.263-12a1.875 1.875 0 0 0-1.865-1.679H16.5V6a4.5 4.5 0 1 0-9 0ZM12 3a3 3 0 0 0-3 3v.75h6V6a3 3 0 0 0-3-3Zm-3 8.25a3 3 0 1 0 6 0v-.75a.75.75 0 0 1 1.5 0v.75a4.5 4.5 0 1 1-9 0v-.75a.75.75 0 0 1 1.5 0v.75Z" clipRule="evenodd" />
                                    </svg>
                                </button>
                            </div>
                        </div>
                    )}
                </div>

                {userGenerationsModal && (
                    <div className='fixed inset-0 bg-black bg-opacity-70 flex items-center justify-center p-6 xl:p-0'>
                        <div className='w-full h-full xl:w-1/2 xl:h-3/4 bg-white rounded-3xl p-4 relative'>
                            <div className='w-6 h-6 bg-black text-white font-semibold rounded-full inline-flex items-center justify-center cursor-pointer absolute -top-2 -right-2' onClick={() => { showUserGenerationsModal(false) }}>X</div>
                            {/* My Designs Section */}
                            <div className='w-full h-full overflow-hidden overflow-y-auto hide-scrollbar'>
                                <UserGenerations setRestyledImageKey={selectRestyledImage} />
                            </div>
                        </div>
                    </div>
                )}

                <Cart />

            </div>
        </>
    )
}