import React, { useEffect, useRef, useState } from 'react';
import { useAuthenticate } from '../contexts/AuthenticateContext';
import { useCart } from '../contexts/CartProvider';
import { useNavigate, useParams } from 'react-router-dom';

export default function TransactionStatusPage() {
    const navigate = useNavigate();
    const { orderType, sessionId } = useParams();
    const { refreshUser } = useAuthenticate();
    const isMounted = useRef(false); // Ref to track whether the component is mounted
    const { cart } = useCart();
    const [orderStatus, setOrderStatus] = useState({ loading: true, error: null, message: null });

    useEffect(() => {
        if (!isMounted.current) { // Check if it's the first render
            isMounted.current = true; // Update the ref to indicate that the component is mounted
            if (sessionId) {
                const fetchCreateOrder = async () => {
                    try {
                        // Make a POST request to the endpoint with session ID in the URL
                        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/payments/transaction-status/${orderType}/${sessionId}`, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': `Bearer ${process.env.REACT_APP_STRIPE_SECRET_KEY}` // Use STRIPE_SECRET_KEY for authorization
                            },
                            body: JSON.stringify({ cart })
                        });

                        // Check if request was successful
                        if (!response.ok) {
                            const errorData = await response.json();
                            throw new Error(errorData.error);
                        }

                        // Handle success
                        const data = await response.json();
                        await refreshUser();
                        setOrderStatus({ loading: false, error: null, message: data.message });
                    } catch (error) {
                        // Handle error
                        setOrderStatus({ loading: false, error: error.message, message: null });
                        console.error('Error creating order:', error.message);
                    }
                };

                fetchCreateOrder();
            }
        }
        // eslint-disable-next-line
    }, [sessionId]); // Dependency on sessionId to re-run the effect when it changes

    return (
        <div className='w-full container mx-auto min-h-[650px] flex items-center justify-center'>
            {orderStatus.loading && !orderStatus.error && !orderStatus.message && (
                <div role="status">
                    <svg aria-hidden="true" className="inline w-4 h-4 text-black animate-spin fill-white" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                    </svg>
                    <span className="sr-only">Loading...</span>
                </div>
            )}

            {!orderStatus.loading && orderStatus.error && !orderStatus.message && (
                <div className="p-6 md:mx-auto max-w-xl">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="text-red-600 w-16 h-16 mx-auto my-6">
                        <circle cx="12" cy="12" r="10" fill="red" />
                        <path fill="white" d="M17.657,6.343a1,1,0,0,0-1.414,0L12,10.586,7.757,6.343a1,1,0,1,0-1.414,1.414L10.586,12,6.343,16.243a1,1,0,0,0,1.414,1.414L12,13.414l4.243,4.243a1,1,0,0,0,1.414-1.414L13.414,12l4.243-4.243A1,1,0,0,0,17.657,6.343Z" />
                    </svg>
                    <div className="text-center">
                        <h3 className="md:text-2xl text-base text-gray-900 font-semibold text-center">{orderStatus.error}</h3>
                        <p className="text-gray-600 my-2">We're sorry, but it seems your order didn't go through. Please check your payment details and try again.</p>
                        <p> If you need further assistance, feel free to contact our support team. </p>
                        <div className="py-10 text-center">
                            <button className="px-12 border-2 border-black bg-lime-400 hover:bg-black hover:text-white rounded-3xl text-black font-semibold py-3" onClick={() => navigate('/', { replace: true })}>
                                GO BACK
                            </button>
                        </div>
                    </div>
                </div>
            )}

            {!orderStatus.loading && !orderStatus.error && orderStatus.message && (
                <div className="p-6 md:mx-auto max-w-xl">
                    <svg viewBox="0 0 24 24" className="text-black w-16 h-16 mx-auto my-6">
                        <path fill="currentColor" d="M12,0A12,12,0,1,0,24,12,12.014,12.014,0,0,0,12,0Zm6.927,8.2-6.845,9.289a1.011,1.011,0,0,1-1.43.188L5.764,13.769a1,1,0,1,1,1.25-1.562l4.076,3.261,6.227-8.451A1,1,0,1,1,18.927,8.2Z"></path>
                    </svg>
                    <div className="text-center">
                        <h3 className="md:text-2xl text-base text-gray-900 font-semibold text-center">{orderStatus.message}</h3>
                        <p className="text-gray-600 my-2">Thank you for completing your secure online payment.</p>
                        <p> Have a great day!  </p>
                        <div className="py-10 text-center">
                            <button className="px-12 border-2 border-black bg-lime-400 hover:bg-black hover:text-white rounded-3xl text-black font-semibold py-3" onClick={() => navigate('/', { replace: true })}>
                                GO BACK
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}