import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ProductsProvider } from './contexts/ProductsContext';
import { AuthenticateProvider } from './contexts/AuthenticateContext';
import { RestyledImagesProvider } from './contexts/RestyledImagesContext';
import { CartProvider } from './contexts/CartProvider'; // Import CartProvider
import Header from './components/Header';
import Footer from './components/Footer';
import NotFound from './pages/NotFound';
import Home from './pages/Home';
import PhotoStyler from './pages/PhotoStyler';
import ScrollToTop from './components/ScrollToTop';
import ToastComponent from './components/ToastComponent';
import Shop from './pages/Shop';
import TransactionStatusPage from './pages/TransactionStatusPage';
import ProductDetails from './pages/ProductDetails';
import UserGenerations from './components/UserGenerations';
import { Dashboard } from './admin';
import { AdminDataProvider } from './contexts/AdminDataContext';
import { TopUpCreditsProvider } from './contexts/TopUpCreditsContext';
import TrackOrder from './pages/TrackOrder';

export default function App() {
  return (
    <Router basename="/">
      <Routes>
        <Route path="/*" element={<MainLayout />} />
        <Route path="/dashboard" element={<AuthenticateProvider><AdminDataProvider><ProductsProvider><Dashboard /></ProductsProvider></AdminDataProvider></AuthenticateProvider>} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
}

function MainLayout() {
  return (
    <ProductsProvider>
      <AuthenticateProvider>
        <RestyledImagesProvider>
          <CartProvider>
            <TopUpCreditsProvider>
              <ScrollToTop />
              <Header />
              <ToastComponent />
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/shop" element={<Shop />} />
                <Route path="/ai-image-restyle" element={<PhotoStyler />} />
                <Route path="/user-generations" element={<UserGenerations />} />
                <Route path="/track-order/:trackingId?" element={<TrackOrder />} />
                <Route path="/product-details/:productId" element={<ProductDetails />} />
                <Route path="/transaction-status/:orderType/:sessionId" element={<TransactionStatusPage />} />
                {/* Add a route for unmatched paths */}
                <Route path="*" element={<NotFound />} />
              </Routes>
              {/* Footer */}
              <Footer />
            </TopUpCreditsProvider>
          </CartProvider>
        </RestyledImagesProvider>
      </AuthenticateProvider>
    </ProductsProvider>
  );
}
