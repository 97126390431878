import React from 'react'
import { useNavigate } from 'react-router-dom';
import Authenticate from '../components/Authenticate';
import TopUpCredits from '../components/TopUpCredits';
import { useAuthenticate } from '../contexts/AuthenticateContext';
import { useTopUpCredits } from '../contexts/TopUpCreditsContext';
import Carousel from '../components/Carousel';
import Cart from '../components/Cart';
import Shop from './Shop';
// Import Images
import animeBoy from '../assets/anime-boy.png';
import girlCartoon from '../assets/girl-cartoon.png';
import femaleCartoon from '../assets/female-cartoon.png';
import underline from '../assets/underline.png';
import HowItWorksPhoto from '../assets/Image-Generation-Leonardo-Ai.png';
import PhotoStyler from './PhotoStyler';
import { motion } from 'framer-motion';

export default function Home() {
  const navigate = useNavigate();
  // Use the useAuthenticate hook to access authentication context
  const { isLoginFormVisible } = useAuthenticate();
  const { isTopUpCreditsVisible } = useTopUpCredits();

  let slides = [
    'https://cdn.leonardo.ai/users/8da03ae7-1a25-4339-8975-3043f7369658/generations/c10e687f-0bae-4154-a352-2ec2ab5f992e/Default_Draw_Lucas_a_smiling_boy_holding_his_faithful_friend_B_0.jpg',
    'https://cdn.leonardo.ai/users/f6f6200b-f8aa-45ff-8555-11f865257f0a/generations/014c098e-7240-4a7b-ab33-4599042d5438/variations/alchemyrefiner_alchemymagic_2_014c098e-7240-4a7b-ab33-4599042d5438_0.jpg',
    'https://firebasestorage.googleapis.com/v0/b/image-restyle.appspot.com/o/assets%2F5.jpg?alt=media&token=d8f4cd35-f6bc-47bd-b534-af4e2655cb3f'];

  return (
    <div>
      {isLoginFormVisible && (
        <Authenticate />
      )}
      {isTopUpCreditsVisible && (
        <TopUpCredits />
      )}

      {/* Section 1 (Hero Section) */}
      <div className='mt-6 w-full container mx-auto px-6 xl:px-0 grid grid-cols-1 gap-12 xl:gap-0 xl:grid-cols-2'>
        <div className='flex flex-col gap-6 items-center xl:items-start justify-center relative'>
          <motion.h1 className='text-black font-extrabold text-4xl sm:text-5xl md:text-6xl lg:text-7xl w-full md:max-w-2xl text-center xl:text-start' viewport={{ once: true }} initial={{ opacity: 0, y: -80 }} whileInView={{ opacity: 1, y: 0 }} transition={{ ease: "easeInOut", duration: 1 }}>
            Upgrade Your Photos with <span className='text-lime-400'>AI Magic</span>
          </motion.h1>
          <motion.p className='text-gray-600 max-w-xl text-sm sm:text-md md:text-lg text-center xl:text-start' viewport={{ once: true }} initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} transition={{ ease: "easeInOut", duration: 1, delay: 0.6 }}>
            Transform your photos into stunning artworks with our AI-powered filters. Unleash your creativity and explore endless possibilities.
          </motion.p>
          <motion.div viewport={{ once: true }} initial={{ opacity: 0, x: -100 }} whileInView={{ opacity: 1, x: 0 }} transition={{ ease: "easeInOut", duration: 1, delay: 0.6 }}>
            <button className='outline-none px-4 py-2 xl:px-6 xl:py-4 text-black font-medium bg-lime-400 border-2 border-black hover:scale-95 hover:bg-lime-400 hover:text-black hover:transition-all ease-in-out duration-300 rounded-full inline-flex items-center gap-2' onClick={() => { navigate('/ai-image-restyle') }}>
              Try Now
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                <path fillRule="evenodd" d="M12 1.5a.75.75 0 01.75.75V4.5a.75.75 0 01-1.5 0V2.25A.75.75 0 0112 1.5zM5.636 4.136a.75.75 0 011.06 0l1.592 1.591a.75.75 0 01-1.061 1.06l-1.591-1.59a.75.75 0 010-1.061zm12.728 0a.75.75 0 010 1.06l-1.591 1.592a.75.75 0 01-1.06-1.061l1.59-1.591a.75.75 0 011.061 0zm-6.816 4.496a.75.75 0 01.82.311l5.228 7.917a.75.75 0 01-.777 1.148l-2.097-.43 1.045 3.9a.75.75 0 01-1.45.388l-1.044-3.899-1.601 1.42a.75.75 0 01-1.247-.606l.569-9.47a.75.75 0 01.554-.68zM3 10.5a.75.75 0 01.75-.75H6a.75.75 0 010 1.5H3.75A.75.75 0 013 10.5zm14.25 0a.75.75 0 01.75-.75h2.25a.75.75 0 010 1.5H18a.75.75 0 01-.75-.75zm-8.962 3.712a.75.75 0 010 1.061l-1.591 1.591a.75.75 0 11-1.061-1.06l1.591-1.592a.75.75 0 011.06 0z" clipRule="evenodd" />
              </svg>
            </button>
            <button className='outline-none text-black font-medium inline-flex items-center gap-2 ml-4' onClick={() => { navigate('/ai-image-restyle') }}>
              Explore Styles
            </button>
          </motion.div>
        </div>
        <motion.div className='w-full h-[400px] xl:h-[550px] relative' viewport={{ once: true }} initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} transition={{ ease: "easeInOut", duration: 1, delay: 0.6 }}>
          <Carousel slides={slides} />
        </motion.div>
      </div>

      {/* Section 2 (How It Works) */}
      <div className='mt-12 xl:mt-24 bg-[#21262a] p-6 xl:p-0 min-h-screen flex items-center justify-center'>
        <div className='container mx-auto grid grid-col-1 xl:grid-cols-2'>
          <div className='w-full xl:w-3/4 p-6 xl:p-0 mx-auto bg-[#a0c752] h-[500px] flex items-center justify-center'>
            <div className='w-full xl:w-3/4 p-6 xl:p-0 mx-auto h-4/5 bg-white flex items-center justify-center'>
              <div className='w-full xl:w-3/4 p-6 xl:p-0 mx-auto h-4/5 bg-black'>
                <img src={HowItWorksPhoto} alt="" className='w-full h-full object-cover' />
              </div>
            </div>
          </div>

          <div className='mt-12 xl:mt-0'>
            <div className='flex flex-col gap-2'>
              <h2 className='text-white font-extrabold text-2xl sm:text-3xl md:text-4xl lg:text-5xl'>How It Works</h2>
              <img src={underline} alt="underline" className='w-1/3' />
            </div>

            <div className='mt-4 flex items-center gap-2'>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-10 text-[#a0c752]">
                <path fillRule="evenodd" d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm13.36-1.814a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z" clipRule="evenodd" />
              </svg>
              <div>
                <span className='text-xl text-[#a0c752] font-medium'>Upload Your Photo</span>
                <p className='text-gray-400 text-xs mt-2 max-w-96'>Choose any photo you love and want to restyle using our innovative tools.</p>
              </div>
            </div>

            <div className='mt-12 flex items-center gap-2'>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-10 text-[#a0c752]">
                <path fillRule="evenodd" d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm13.36-1.814a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z" clipRule="evenodd" />
              </svg>
              <div>
                <span className='text-xl text-[#a0c752] font-medium'>Select AI Filters</span>
                <p className='text-gray-400 text-xs mt-2 max-w-96'>Explore our wide range of AI filters to transform your photo into a work of art.</p>
              </div>
            </div>

            <div className='mt-12 flex items-center gap-2'>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-10 text-[#a0c752]">
                <path fillRule="evenodd" d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm13.36-1.814a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z" clipRule="evenodd" />
              </svg>
              <div>
                <span className='text-xl text-[#a0c752] font-medium'>Download or Print</span>
                <p className='text-gray-400 text-xs mt-2 max-w-96'>Download your styled photo to keep memories alive or print it on any product of your choice.</p>
              </div>
            </div>

            <motion.div className='mt-12' viewport={{ once: true }} initial={{ opacity: 0, x: -100 }} whileInView={{ opacity: 1, x: 0 }} transition={{ ease: "easeInOut", duration: 1, delay: 0.6 }}>
              <button className='outline-none px-4 py-2 xl:px-6 xl:py-4 text-black font-medium bg-lime-400 border-2 border-black hover:scale-95 hover:bg-lime-400 hover:text-black hover:transition-all ease-in-out duration-300 rounded-full inline-flex items-center gap-2' onClick={() => { navigate('/ai-image-restyle') }}>
                AI Image Restyle
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                  <path fillRule="evenodd" d="M12 1.5a.75.75 0 01.75.75V4.5a.75.75 0 01-1.5 0V2.25A.75.75 0 0112 1.5zM5.636 4.136a.75.75 0 011.06 0l1.592 1.591a.75.75 0 01-1.061 1.06l-1.591-1.59a.75.75 0 010-1.061zm12.728 0a.75.75 0 010 1.06l-1.591 1.592a.75.75 0 01-1.06-1.061l1.59-1.591a.75.75 0 011.061 0zm-6.816 4.496a.75.75 0 01.82.311l5.228 7.917a.75.75 0 01-.777 1.148l-2.097-.43 1.045 3.9a.75.75 0 01-1.45.388l-1.044-3.899-1.601 1.42a.75.75 0 01-1.247-.606l.569-9.47a.75.75 0 01.554-.68zM3 10.5a.75.75 0 01.75-.75H6a.75.75 0 010 1.5H3.75A.75.75 0 013 10.5zm14.25 0a.75.75 0 01.75-.75h2.25a.75.75 0 010 1.5H18a.75.75 0 01-.75-.75zm-8.962 3.712a.75.75 0 010 1.061l-1.591 1.591a.75.75 0 11-1.061-1.06l1.591-1.592a.75.75 0 011.06 0z" clipRule="evenodd" />
                </svg>
              </button>
              <button className='outline-none text-white font-medium inline-flex items-center gap-2 ml-4' onClick={() => { navigate('/ai-image-restyle') }}>
                Explore Styles
              </button>
            </motion.div>
          </div>
        </div>
      </div>

      {/* Section 3 (AI Image Restyle) */}
      <div className='mt-12 xl:mt-24'>
        <PhotoStyler />
      </div>

      {/* Horizontal Line */}
      <div className='mt-12 xl:mt-24 w-full flex items-center'>
        <div className='w-full h-[1px] bg-gradient-to-r from-white via-lime-400 to-white'></div>
        <div className='border-[1px] border-lime-400 px-3 py-2 text-gray-600 text-xs font-light rounded-full shrink-0'>
          Print On Demand
        </div>
        <div className='w-full h-[1px] bg-gradient-to-r from-white via-lime-400 to-white'></div>
      </div>

      {/* Section 4 (Explore Filters) */}
      <div className='mt-12 xl:mt-24 w-full container mx-auto px-6 xl:px-0'>
        <div className='max-w-md mt-12 text-xl xl:text-4xl leading-tight font-light'>Print your AI creations on popular products!</div>

        <div className='mt-6 xl:mt-12 grid grid-cols-1 xl:grid-cols-3 grid-rows-3 gap-4 xl:h-[500px]'>
          {/* Grid Item 1 */}
          <div className='relative h-[300px] xl:h-full row-span-3 overflow-hidden rounded-md flex xl:items-end'>
            <div className='absolute inset-0 bg-cover bg-no-repeat hover:scale-110 transition-all ease-in-out duration-300' style={{ backgroundImage: `url(${animeBoy})` }}></div>
            <div className='absolute w-full h-full xl:w-fit xl:h-fit flex flex-col items-center justify-center xl:items-start xl:justify-end gap-2 px-2 pt-12 pb-2 xl:p-4 bg-black xl:bg-transparent bg-opacity-60'>
              <div className='text-3xl text-white'>CyberTech</div>
              <div className='text-xs text-gray-500 max-w-48'>Transform your photo with our CyberTech filter</div>
              <div className='px-6 py-3 bg-lime-400 rounded-3xl text-black flex items-center gap-1 cursor-pointer hover:text-black hover:bg-lime-400 transition-all ease-in-out duration-300' onClick={() => { navigate('/ai-image-restyle') }}>
                Try Now
              </div>
            </div>
          </div>

          {/* Grid Item 2 */}
          <div className='row-span-3 grid grid-cols-1 grid-rows-8 gap-4'>
            <div className='relative h-[300px] xl:h-full row-span-4 overflow-hidden rounded-md flex xl:items-end'>
              <div className='absolute inset-0 bg-cover bg-no-repeat hover:scale-110 transition-all ease-in-out duration-300' style={{ backgroundImage: `url(${girlCartoon})` }}></div>
              <div className='absolute w-full h-full xl:w-fit xl:h-fit flex flex-col items-center justify-center xl:items-start xl:justify-end gap-2 px-2 pt-12 pb-2 xl:p-4 bg-black xl:bg-transparent bg-opacity-70'>
                <div className='text-3xl text-white xl:text-black'>Anime Style</div>
                <div className='text-xs text-gray-300 max-w-48 xl:text-gray-600'>Give your photos a vibrant Anime Style makeover</div>
                <div className='px-6 py-3 bg-lime-400 rounded-3xl text-black flex items-center gap-1 cursor-pointer hover:text-black hover:bg-lime-400 transition-all ease-in-out duration-300' onClick={() => { navigate('/ai-image-restyle') }}>
                  Try Now
                </div>
              </div>
            </div>

            <div className='h-[300px] xl:h-full row-span-4 bg-green-950 overflow-hidden rounded-md flex flex-col items-center xl:items-start justify-center gap-4 p-6'>
              <div className='text-white border-2 border-white rounded-3xl px-4 py-1'>ImagicaAI</div>
              <div className='text-white text-xl font-semibold'>AI Image Restyle</div>
              <div className='text-lime-400 text-xl font-medium'>#TRYFORFREE</div>
            </div>
          </div>

          {/* Grid Item 3 */}
          <div className='relative h-[300px] xl:h-full row-span-3 overflow-hidden rounded-md flex xl:items-end'>
            <div className='absolute inset-0 bg-cover bg-no-repeat hover:scale-110 transition-all ease-in-out duration-300' style={{ backgroundImage: `url(${femaleCartoon})` }}></div>
            <div className='absolute w-full h-full xl:w-fit xl:h-fit flex flex-col items-center justify-center xl:items-start xl:justify-end gap-2 px-2 pt-12 pb-2 xl:p-4 bg-black xl:bg-transparent bg-opacity-70'>
              <div className='text-3xl text-white'>Cartoon Style</div>
              <div className='text-xs text-gray-300 max-w-48'>Transform your photos into charming Cartoon Style creations</div>
              <div className='px-6 py-3 bg-lime-400 rounded-3xl text-black flex items-center gap-1 cursor-pointer hover:text-black hover:bg-lime-400 transition-all ease-in-out duration-300' onClick={() => { navigate('/ai-image-restyle') }}>
                Try Now
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Section 5 (Shop Collection) */}
      <div className='mt-12 xl:mt-24'>
        <Shop />
      </div>

      {/* Section 6 (Pick Of the Week Section) */}
      <div className='mt-12 xl:mt-24  w-full container mx-auto px-6 xl:px-0'>
        <h2 className='text-xl xl:text-2xl font-normal'>Pick of the week</h2>
        <div className='mt-8 grid grid-cols-1 xl:grid-cols-2 gap-12'>
          <div className='h-full xl:h-[300px] flex flex-col xl:flex-row items-center justify-between bg-orange-100 rounded-lg'>
            <div className='flex flex-col items-center xl:items-start gap-6 p-8'>
              <div className='text-3xl'>Winter Hoodie</div>
              <div className='text-xl'>30%-70% OFF</div>
              <div className='px-6 py-3 bg-black rounded-3xl text-white flex items-center gap-1 cursor-pointer hover:text-black hover:bg-lime-400 transition-all ease-in-out duration-300'>
                Shop The Sale
              </div>
            </div>
            <img src="https://framerusercontent.com/images/3UUdgDVF7lReeHvgdoi3KXB5BFM.png?scale-down-to=512" alt="" className='h-[250px] xl:h-3/4 object-contain' />
          </div>

          <div className='h-full xl:h-[300px] flex flex-col xl:flex-row items-center justify-between bg-sky-100 rounded-lg'>
            <div className='flex flex-col items-center xl:items-start gap-6 p-8'>
              <div className='text-3xl'>Leather  Sneakers</div>
              <div className='text-xl'>20%-40% OFF</div>
              <div className='px-6 py-3 bg-black rounded-3xl text-white flex items-center gap-1 cursor-pointer hover:text-black hover:bg-lime-400 transition-all ease-in-out duration-300'>
                Shop The Sale
              </div>
            </div>
            <img src="https://framerusercontent.com/images/8MQplFqaBBL3Q7HHdFzlOUOX6g.png?scale-down-to=512" alt="" className='h-[250px] xl:h-3/4 object-contain' />
          </div>
        </div>
      </div>

      <Cart />

    </div>
  )
}