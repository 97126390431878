import React, { createContext, useContext, useState, useEffect } from 'react';
import {
  auth,
  GoogleAuthProvider,
  signInWithPopup,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  updateProfile,
  signOut,
  getAdditionalUserInfo
} from '../firebase/firebaseFunctions'; // Assuming these are correct Firebase authentication functions

const AuthenticateContext = createContext();

export const AuthenticateProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [isLoginFormVisible, setLoginFormVisible] = useState(false);
  const [authStatus, setAuthStatus] = useState({ isLoading: true, loginMethod: null });
  const [error, setError] = useState(null);

  useEffect(() => {
    if (isLoginFormVisible) {
      document.body.style.overflow = 'hidden'; // Disable scrolling
    } else {
      document.body.style.overflow = 'auto'; // Enable scrolling
    }
  }, [isLoginFormVisible]);

  const toggleLoginForm = () => {
    setLoginFormVisible(prevVisibility => !prevVisibility);
  };

  const refreshUser = async () => {
    try {
      const user = auth.currentUser;
      if (!user) return;

      await user.reload();

      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/user/${user.uid}/customClaims`);
      if (!response.ok) throw new Error('Failed to fetch custom claims');

      const responseData = await response.json();
      setCurrentUser(prevUser => ({
        ...user,
        ...responseData.customClaims
      }));
    } catch (error) {
      console.error('Error refreshing user:', error.message);
    }
  };

  const handleAuth = async (authFunction, loginMethod, displayName) => {
    try {
      setAuthStatus({ isLoading: true, loginMethod });
      const result = await authFunction();

      if (result && result.user) {
        toggleLoginForm();
        const { user } = result;

        if (getAdditionalUserInfo(result).isNewUser) {
          await updateProfile(user, { displayName: displayName || user.displayName });

          const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/user/${user.uid}/customClaims`, {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' }
          });

          if (response.ok) {
            console.log('New user profile updated with default subscription plan and credits, and custom claims set');
            await refreshUser();
          } else {
            throw new Error('Failed to update custom claims');
          }
        } else {
          console.log('Authentication success:', user);
        }
      }
      setError(null);
    } catch (error) {
      console.error('Authentication error:', error.message);
      setError(error.message);
    } finally {
      setAuthStatus({ isLoading: false, loginMethod: null });
    }
  };

  const signInWithGoogle = () => handleAuth(() => signInWithPopup(auth, new GoogleAuthProvider()), 'Google');
  const signInWithEmail = (email, password) => handleAuth(() => signInWithEmailAndPassword(auth, email, password), 'Email');
  const createUserWithEmail = async (displayName, email, password) => handleAuth(async () => await createUserWithEmailAndPassword(auth, email, password), 'Email', displayName);

  const logout = () => handleAuth(() => signOut(auth), 'Logout');

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        try {
          setAuthStatus({ isLoading: true, loginMethod: null }); // Start loading
          await refreshUser();
        } catch (error) {
          console.error('Error fetching custom claims:', error.message);
        } finally {
          setAuthStatus({ isLoading: false, loginMethod: null }); // Stop loading
        }
      } else {
        setCurrentUser(null);
        setAuthStatus({ isLoading: false, loginMethod: null }); // No user, stop loading
      }
    });

    return () => {
      unsubscribe();
      document.body.style.overflow = 'auto'; // Reset overflow when unmounting
    };
  }, []);

  const contextValues = {
    isLoginFormVisible,
    toggleLoginForm,
    refreshUser,
    currentUser,
    signInWithGoogle,
    signInWithEmail,
    createUserWithEmail,
    logout,
    authStatus,
    error
  };

  return (
    <AuthenticateContext.Provider value={contextValues}>
      {children}
    </AuthenticateContext.Provider>
  );
};

export const useAuthenticate = () => {
  const context = useContext(AuthenticateContext);
  if (!context) {
    throw new Error('useAuthenticate must be used within an AuthenticateProvider');
  }
  return context;
};
