import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useProducts } from '../contexts/ProductsContext';
import Cart from '../components/Cart';
import { motion } from 'framer-motion';

export default function Shop() {
    const location = useLocation();
    // Use the useProducts() hook to access the products array
    const { products } = useProducts();

    const [selectedCategory, setSelectedCategory] = useState(null);
    const navigate = useNavigate();

    const handleCategoryClick = (category) => {
        setSelectedCategory(category);
    };

    const handleProductClick = (productId) => {
        navigate(`/product-details/${productId}`, { state: { restyledImageKey: location?.state?.restyledImageKey } });
    };

    // Default products to an empty array if null
    const productList = products || [];

    // Safely access products array and filter products based on selected category
    const filteredProducts = selectedCategory ? productList.filter(product => product?.category?.toLowerCase() === selectedCategory?.toLowerCase()) : productList;

    return (products.length > 0 &&
        <section className={`w-full container mx-auto px-6 xl:px-0 mt-8 ${location.pathname !== '/' ? 'min-h-screen' : ''}`}>
            <motion.div className={`mt-4 text-lime-400 font-bold ${location.pathname !== '/' ? '' : 'text-center'}`} viewport={{ once: true }} initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} transition={{ ease: "easeInOut", duration: 1, delay: 0.6 }}>
                Print On Demand
            </motion.div>
            <motion.h2 className={`text-xl xl:text-5xl font-bold ${location.pathname !== '/' ? '' : 'text-center'}`} viewport={{ once: true }} initial={{ opacity: 0, x: -100 }} whileInView={{ opacity: 1, x: 0 }} transition={{ ease: "easeInOut", duration: 1, delay: 0.6 }}>
                {location.pathname === '/' ? 'Our Collection' : (location.pathname === '/shop' && location?.state?.restyledImageKey ? 'Choose A Product' : 'Shop Collection')}
            </motion.h2>
            <motion.p className={`mt-4 text-xs xl:text-sm text-gray-600 ${location.pathname !== '/' ? '' : 'text-center max-w-lg mx-auto'}`} viewport={{ once: true }} initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} transition={{ ease: "easeInOut", duration: 1, delay: 0.6 }}>
                Discover our stylish and comfortable clothing line designed to inspire creativity.
            </motion.p>

            <motion.div className={`mt-4 flex flex-wrap items-center gap-4 ${location.pathname !== '/' ? '' : 'justify-center'}`} viewport={{ once: true }} initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} transition={{ ease: "easeInOut", duration: 1, delay: 0.6 }}>
                {/* Handle "All" category separately */}
                <div className='shrink-0 w-[120px] py-2 text-center border-2 border-black rounded-3xl cursor-pointer capitalize' onClick={() => handleCategoryClick(null)}>
                    All
                </div>
                {[...new Set(productList.map(product => product?.category?.toLowerCase()))].map((category, index) => (
                    <div key={index} className='shrink-0 w-[120px] py-2 text-center border-2 border-black rounded-3xl cursor-pointer capitalize' onClick={() => handleCategoryClick(category)}>
                        {category}
                    </div>
                ))}
            </motion.div>

            <motion.div className='grid grid-cols-2 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-4 gap-6 mt-10' viewport={{ once: true }} initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} transition={{ ease: "easeInOut", duration: 1, delay: 0.6 }}>
                {filteredProducts.map((product, index) => (
                    <div key={index} className="bg-white shadow-md rounded-lg overflow-hidden transform hover:scale-105 transition-transform duration-300 cursor-pointer" onClick={() => handleProductClick(product.id)}>
                        <img
                            src={product?.thumbnail}
                            alt={product?.name}
                            className="h-44 sm:h-52 w-full object-contain"
                        />
                        <div className="p-4">
                            <span className="text-gray-400 uppercase text-xs block">{product?.category}</span>
                            <div className="flex items-center justify-between">
                                <p className="text-xs sm:text-base md:text-lg lg:text-lg font-bold text-black truncate capitalize mt-2">{product?.name}</p>

                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-bag-plus cursor-pointer" viewBox="0 0 16 16">
                                    <path fillRule="evenodd" d="M8 7.5a.5.5 0 0 1 .5.5v1.5H10a.5.5 0 0 1 0 1H8.5V12a.5.5 0 0 1-1 0v-1.5H6a.5.5 0 0 1 0-1h1.5V8a.5.5 0 0 1 .5-.5z" />
                                    <path d="M8 1a2.5 2.5 0 0 1 2.5 2.5V4h-5v-.5A2.5 2.5 0 0 1 8 1zm3.5 3v-.5a3.5 3.5 0 1 0-7 0V4H1v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V4h-3.5zM2 5h12v9a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V5z" />
                                </svg>
                            </div>
                        </div>
                    </div>
                ))}
            </motion.div>

            <Cart />
        </section>
    );
}